import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'auto',
  },
  coin: {
    position: 'relative',
    width: '50px',
    height: '50px',
    perspective: '1000px',
    cursor: 'pointer',
  },
  coinInner: {
    position: 'relative',
    width: '100%',
    height: '100%',
    textAlign: 'center',
    transition: 'transform 0.6s',
    transformStyle: 'preserve-3d',
  },
  side: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backfaceVisibility: 'hidden',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  heads: {
    backgroundColor: '#fff',
    zIndex: 2,
  },
  tails: {
    backgroundColor: '#ccc',
    transform: 'rotateY(180deg)',
  },
  spinning: {
    animation: `$spin 1.2s ease-in-out 2`, // Two spins on load
  },
  flipping: {
    transform: 'rotateY(180deg)',
  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotateY(0deg)',
    },
    '100%': {
      transform: 'rotateY(720deg)', // 720deg = 2 spins
    },
  },
}));

const CoinFlip = () => {
  const classes = useStyles();
  const [isFlipped, setIsFlipped] = useState(false);
  const [initialSpin, setInitialSpin] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setInitialSpin(false); // Stop the initial spin after it completes
    }, 1200); // Duration of the initial spin

    return () => clearTimeout(timer);
  }, []);

  const flipCoin = () => {
    setIsFlipped(!isFlipped); // Toggle between flipping the coin
  };

  return (
    <div className={classes.root}>
      <div className={classes.coin} onClick={flipCoin}>
        <div className={`${classes.coinInner} ${initialSpin ? classes.spinning : ''} ${isFlipped ? classes.flipping : ''}`}>
          <div className={`${classes.side} ${classes.heads}`}>
            <img src="https://storage.googleapis.com/app_darkendimg/assets/kcoin.png" alt="Heads" style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
          </div>
          <div className={`${classes.side} ${classes.tails}`}>
            <img src="https://storage.googleapis.com/app_darkendimg/assets/bec.png" alt="Tails" style={{ width: '50px', height: '50px', objectFit: 'cover' }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoinFlip;
