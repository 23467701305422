import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import { gql, useQuery,  } from '@apollo/client';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import { DialogActions, TextField , DialogContent, Button, Icon, Divider} from '@mui/material';
import { Link } from 'react-router-dom';
import StoryCard from '../../../components/mods/comments';
import {  Drawer } from '@mui/material'; 
import { UserContext } from '../../../context/usercontext';
import { useContext, useState } from 'react';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import Grow from '@mui/material/Grow';
import { useEffect } from 'react';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { GET_ALL_USERS } from '../../../context/gqllogics';
import { Close } from '@mui/icons-material';
import UserDet from '../../../pages/userdeets';
import { useSpring, animated, config, useTransition } from 'react-spring';
import Framer from '../../mods/displays/testanime';
import { motion, useAnimation, AnimatePresence } from 'framer-motion';
import Dhome from '../../../pages/homedesk';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import useFileUpload  from '../cloud';
import EditIcon from '@mui/icons-material/Edit';
import { useMutation } from '@apollo/client';
import { CollaborateButton, UncollaborateButton, CollaboratorsCount } from '../settings/CollaborateComponents';
import { useNavigate } from 'react-router-dom';

function formatBeanCount(number) {
  if (number < 1000) {
    return number; // return the same number if less than 1000
  } else if (number < 1000000) { // for numbers between 1000 and 999,999
    return (number / 1000).toFixed(1) + 'k'; // one decimal place with 'k'
  } else {
    return (number / 1000000).toFixed(1) + 'M'; // one decimal place with 'M' for numbers 1,000,000 and above
  }
}

// Define the animation properties
const slideUpVariants = {
    hidden: { opacity: 0, y: 50 }, // Start position
    visible: { opacity: 1, y: 0 },  // End position
  };
  
  const Transition = React.forwardRef(function Transition(props, ref) {
    return (
      <Grow
        ref={ref}
        {...props}
        timeout={{
          enter: 700, // Duration for the grow-in effect
          exit: 500,  // Duration for the shrink-out effect
        }}
        easing={{
          enter: 'cubic-bezier(0.4, 0, 0.2, 1)', // Custom easing function
          exit: 'cubic-bezier(0.4, 0, 0.2, 1)',
        }}
      />
    );
  });
  

const useStyles = makeStyles(() => ({
  root: {
    
    borderRadius: 4,
    boxShadow: 'none',
      transition: '0.3s',
      backgroundColor: 'transparent !important',
      width: '100%',
     
  },
  content: {
    padding: 5,
    spacing: 8,
  },
  brandCardHeader: {
    width: '100%',
    minHeight: 500,
  },
  textInfoContent: {
    // Add styles for TextInfoContent here
  },
  commentsContainer: {
    maxHeight: '400px',  // Set a max height that works for you
    overflowY: 'auto',  // Enable vertical scrolling
  },
  stickyArticle: {
    position: 'sticky',
    top: 0,
    zIndex: 10,
    backgroundColor: 'white',  // Set a background color to cover any content scrolling behind it
  },
}));






const GET_STORYS = gql`
    query{
        krons (limit: 600){
            year 
            What
            ImageUrl
            _id
            countryName
        }
    }
`;

const GET_USERS_BY_TYPE = gql`
  query userprofs ($usertype: String!) {
    userprofs (query: {usertype: $usertype}) {
      _id
      Nickname  
      Title
      Koffie_story
      usertype
      imageurl
      beanCount
    }
  }
`;

const GET_USER_PROD_QUERY = gql`
  query GetUserGoals($userID: String!) {
  products(query: {userID: $userID}) {
    prompt
    _id
    userID
    name
    price
    
    imageurl
    
  }
}  
`;

const GET_USER_POST_QUERY = gql`
  query GetUserPosts($userID: String!) {
  posts(query: {userID: $userID}) {

    _id
    userID
    
    content
    imageurl
    
  }
}
`;

//Mutate Koffie
const UPDATE_USERPROF = gql`
  mutation updateOneUserprof($query: UserprofQueryInput, $set: UserprofUpdateInput!) {
    updateOneUserprof(query: $query, set: $set) {
      _id
      name
      description
      country   
      tasteProfile
      purchaseLink
      Koffie_story
      imageurl
    }
  }
`; 


const ScrollableContainer = styled(Box)({
  display: 'flex',
  overflowX: 'scroll',
  padding: '16px',
  gap: '16px',
  zIndex: 1000,
  marginLeft: '-16px',  // Adjust based on the page padding
  marginRight: '-16px', // Adjust based on the page padding
  width: 'calc(100% + 32px)',  // Adjust based on the margins to cover the full viewport
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '-ms-overflow-style': 'none',  /* IE and Edge */
  'scrollbar-width': 'none',  /* Firefox */
});


const ScrollCard = styled(Card)({
  width: '250px',
  
  flex: '0 0 auto',
  overflow: 'hidden',
  cursor: 'pointer',
  boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  color: '#352536'
});

const datastuff = [
  {
    id: 1,
    title: 'First item',
    content: 'This is the first item',
  },
  {
    id: 2,
    title: 'Second item',
    content: 'This is the first input',
  },
  {
    id: 3,
    title: 'Third item',
    content: 'This is the first input',
  },
  {
    id: 4,
    title: 'Fourth item',
    content: 'This is the first input',
  },
  {
    id: 5,
    title: 'Fifth item',
    content: 'This is the first input',
  },
  {
    id: 6,
    title: 'Sixth item',
    content: 'This is the first input',
  },
  {
    id: 7,
    title: 'Seventh item',
    content: 'This is the first input',
  },
  {
    id: 8,
    title: 'Eighth item',
    content: 'This is the first',
  },
  {
    id: 9,
    title: 'Ninth item',
    content: 'This is the first input',
  },
  {
    id: 10,
    title: 'Tenth item',
    content: 'This is the first input',
  },
];

const kupakoffie = () => {
  return {
    "name": "Barista",
    "description": "Give info based on available data.",
    "parameters": {
      "type": "object",
      "properties": {
        "Title": {
          "type": "string",
          "description": "Title of Subject"
        },
        "price": {
          "type": "string",
          "description": "do not output dollar sign. Integer Only."
        },
        "description": {
          "type": "string",
          "description": "Tell a short tale of the events in what happened."
        },
        "productID": {
          "type": "string",
          "description": "The product ID"
        },

          "primaryImage": 
          {"type": "string",
          "description": "The url of the primary image"
        
        },
        "imageurl":
        {"type": "string",
        "description": "The URL of userimage"
      },
     

      
        "When": 
          {"type": "string",
          "description": "When is this available"
        
        },

        "userID":
        {"type": "string",
        "description": "The user ID"
      }
      
        
      },
      
      "required": ["name", "Title", "description",  "primaryImage", "When",   "price", "productID", "userID", "imageurl"]
    }
  };
};

export default function BaristaList(kron, userId) {
  const { user } = useContext(UserContext);
  
  const [yearFilter, setYearFilter] = React.useState("");
  const [sortedData, setSortedData] = React.useState([]);
  const [expandedCards, setExpandedCards] = React.useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const classes = useStyles();
  const [selectedShop, setSelectedShop] = useState(null);
   const [dialogOpen, setDialogOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [value, setValue] = React.useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const [drawerOpen1, setDrawerOpen1] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isChangingImage, setIsChangingImage] = useState(false);
  const [koffieStory, setKoffieStory] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const theme = useTheme();
  const [content, setOutput] = useState('');
  const [updateUserProf] = useMutation(UPDATE_USERPROF);
  const [dialogOpen1, setDialogOpen1] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [openDialog1, setOpenDialog1] = useState(true);
  const { file, previewURL, handleFileChange, uploadFile, handleRemoveImage  } = useFileUpload(); // Use your custom hook
  const fileInputRef = React.createRef();
  const { user: currentUser } = useContext(UserContext);
  console.log("Current user:", currentUser);
  const [isCollaborating, setIsCollaborating] = useState(false);
  const navigate = useNavigate();
  
 
  const handleCollaborate = () => {
    // Handle collaboration logic here (e.g., API call)
    setIsCollaborating(true); // Switch to Uncollaborate button
  };

  const handleUncollaborate = () => {
    // Handle uncollaboration logic here (e.g., API call)
    setIsCollaborating(false); // Switch back to Collaborate button
  };

  const { data: userData } = useQuery(GET_USER_PROD_QUERY, {
    variables: { userID: currentUser?.id },
  });

  useEffect(() => {
    // Delay the appearance of the button to sync with the dialog's transition
    const timer = setTimeout(() => setIsButtonVisible(true), 1000); // Adjusted to 1000ms
    return () => clearTimeout(timer);
  }, []);
  
  const buttonVariants = {
    hidden: { opacity: 0, y: 100 }, // Start the button lower on the Y-axis
    visible: { 
      opacity: 1, 
      y: 0, // Slide up to its final position
      transition: { 
        type: "spring", 
        stiffness: 120, 
        damping: 15, 
        delay: 0.3 // Adjust delay as needed
      }
    },
    hover: { 
      scale: 1.05, // Slightly smaller scale to avoid abruptness
      transition: { duration: 0.3 }
    }
  };
 
 // uspring animation
 const animationStyles = useSpring({
    from: { transform: 'translateY(-100%)', opacity: 0 },
    to: async (next) => {
      // Falls and bounces into place more slowly
      await next({ transform: 'translateY(0%)', opacity: 1, config: { ...config.wobbly, tension: 170, friction: 12 } });
      // Zooms out slowly
      await next({ transform: 'scale(1.2)', opacity: 1, config: { duration: 1000 } });
      // Zooms back in to its original state more slowly
      await next({ transform: 'scale(1)', opacity: 1, config: { duration: 1000 } });
      // Optional: keeps the item visible without disappearing, remove the next line if you want it to disappear after zooming back in
      await next({ opacity: 1, config: { duration: 500 } });
    },
    delay: 600,
  });

  const dialogAnimation = useSpring({
    transform: openDialog ? 'scale(1) translateY(0)' : 'scale(0.8) translateY(-50%)',
    opacity: openDialog ? 1 : 0,
    config: { tension: 300, friction: 20 }, // Customize the animation speed and smoothness
  });

  const { loading, error, data } = useQuery(GET_USERS_BY_TYPE, {
    variables: { usertype: "barista" },
  });


const filteredShops = (data?.userprofs || []).filter(shop =>
    shop.Nickname.toLowerCase().includes(searchTerm.toLowerCase())
  );

  //Handle Image Upload
  const handleImageUpload = () => {
   
    const fileInput = fileInputRef.current;
    fileInput.click();
  }
  const handleChangeScreen = () => {
    setIsChangingImage(true);
  };

  const handleCancelChange = () => {
    setIsChangingImage(false);
  };

  const handlePostAndUpload = async () => {
    if (!file) {
      console.error('No file selected');
      return;
    }
    
  
    setIsUploading(true); // Start uploading
  
    const uploadedImageURL = await uploadFile(file, user.id);
  
    // Check if the upload was unsuccessful
    if (!uploadedImageURL) {
      console.error('File upload failed: no image URL returned');
      setIsUploading(false);
      updateUserProf({
        variables: {
          query: { _id: user.id },
          set: { imageurl: uploadedImageURL },
        },
      });
     
      return;
    }
  
    // Update the imageurl for userprof
    updateUserProf({
      variables: {
        query: { _id: user.id },
        set: { imageurl: uploadedImageURL },
      },
      
    });
    

    setIsChangingImage(false);
    setIsUploading(false);
    
   
  
  }
    
  const handleOpenDialog = (shop) => {
    setSelectedShop(shop);
    setOpenDialog(true);
  };
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleCardClick = (card) => {
    setSelectedCard(card);
    setDialogOpen(true);
  };

 // Function to handle closing the dialog
 const handleDialogClose = (id) => {
  setExpandedCards(prevState => ({
    ...prevState,
    [id]: false,
  }));
};

  const handleDrawerToggle = () => {
    setDrawerOpen((prev) => !prev);
    console.log("Drawer toggled. New state:", !drawerOpen);
  };

  console.log("Drawer open state:", drawerOpen);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  

  const handleExpandClick = (id) => {
    setExpandedCards((prev) => ({ ...prev, [id]: !prev[id] }));
    setDialogOpen(false);
  };

const handleDrawerToggle1 = () => {
    setDrawerOpen1((prev) => !prev);
    console.log("Drawer toggled. New state:", !drawerOpen1);
    };


    const isOwner = user && (user.id === currentUser.id);

  
    console.log("Is owner:", isOwner);
  
  
  

  if (loading) return <p></p>;
  if (error) return <p>Error :</p>;

 const mydata = [
    {
      id: 1,
      title: 'First item',
      content: 'This is the first item',
    },
    {
      id: 2,
      title: 'Second item',
      content: 'This is the first input',
    },
    {
      id: 3,
      title: 'Third item',
      content: 'This is the first input',
    },
    {
      id: 4,
      title: 'Fourth item',
      content: 'This is the first input',
    },
    {
      id: 5,
      title: 'Fifth item',
      content: 'This is the first input',
    },
    {
      id: 6,
      title: 'Sixth item',
      content: 'This is the first input',
    },
    {
      id: 7,
      title: 'Seventh item',
      content: 'This is the first input',
    },
    {
      id: 8,
      title: 'Eighth item',
      content: 'This is the first',
    },
    {
      id: 9,
      title: 'Ninth item',
      content: 'This is the first input',
    },
    {
      id: 10,
      title: 'Tenth item',
      content: 'This is the first input',
    },
 ]

  return (
    <div>
      
    <Box sx={{ flexGrow: 1 ,  }}>
    
     
        <Box display="flex" flexWrap="wrap"sx={{height: '100vh'}}>
        
        
          {filteredShops?.map((userprof) => (
            <Box key={userprof._id} m={1}sx={{ }}>
              
                <Card >
  <Link to={`/barista/${userprof._id}`}>
    <CardMedia
      component="img"
     
      image={userprof.imageurl}
      alt="Koffie"
      sx={{
        width: '100%',         // Make the image take up 100% of the container width
        height: 'auto',        // Adjust height to maintain aspect ratio
        objectFit: 'cover',    // Ensures the image covers the entire area of the container
        borderRadius: 4,
      }}
    />
  </Link>
  <CardContent>
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 0 }}>
    <Typography variant="body2" fontWeight={700}>
      {userprof.Nickname}
    </Typography>
    <Typography variant="body2"  sx={{ ml: 'auto' }}>
    <Typography fontWeight={700}>{formatBeanCount(userprof.beanCount)} </Typography>
    </Typography>
    </Box>
    <Typography
  variant="body2"
  color="text.secondary"
  sx={{
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    textOverflow: 'ellipsis',
  }}
>
  {userprof.Koffie_story}
</Typography>

  </CardContent>
</Card>

              
              <Typography variant="caption">{kron.year}</Typography>
              
              <Link to={`/kupster/${userprof._id}`}>
              <ChatBubbleOutlineIcon
            sx={{ backgroundColor: 'transparent' }}
           
        />
           </Link>
                <Dialog fullScreen  TransitionComponent={Transition} sx={{height: '100vh'}} open={expandedCards[userprof._id]} onClose={() => handleExpandClick(kron._id)}
                    >
                <animated.div >
    <div >
    
       
     
      <Card className={classes.root}>
      <Card
  key={userprof._id}
  sx={{
    borderRadius: 0,
    margin: 0,
    backgroundImage: isChangingImage ? 'none' : `url(${userprof?.imageurl})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    minHeight: 250,
    width: '100%',
    objectFit: 'cover',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    justifyItems: 'center',
    padding: 5,
    position: 'relative', // Add position relative to the card
  }}
>
  <Box sx={{ display: 'flex', justifyContent: 'flex-start', position: 'absolute', top: 10, left: 5 }}>
    <IconButton
      onClick={() => handleDialogClose(userprof._id)}
      sx={{
        color: '#077336',
        backgroundColor: '#ebecf0',
      }}
    >
      <Close />
    </IconButton>
  </Box>
  <div>
    {isOwner && !isChangingImage && (
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, position: 'absolute', bottom: 16, right: 16, backgroundColor: '#ebecf0', }}>
        <EditIcon
          onClick={handleChangeScreen}
          sx={{
            color: '#077336',
          }}
        />
        <Typography variant="caption" sx={{ fontWeight: 600, textShadow: '2px 2px 8px rgba(0, 0, 0, 0.1)' }}>
          Change Image
        </Typography>
      </Box>
    )}
  </div>

  {isChangingImage ? (
    <>
      {previewURL && (
        <Box position="relative" display="inline-block">
          <CardMedia
            className="fade-in"
            component="img"
            alt="Preview"
            image={previewURL}
            sx={{ width: '400px', height: '250px', maxWidth: '100%', borderRadius: 2 }}
          />
          <IconButton
            onClick={handleRemoveImage}
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              color: 'grey.900',
              backgroundColor: 'common.white',
              '&:hover': {
                backgroundColor: 'grey.100',
              },
            }}
          >
            <Close />
          </IconButton>
        </Box>
      )}
      <Box>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, padding: 2 }}>
          <button className={classes.buttonContainer} onClick={() => fileInputRef.current.click()}>Select Image</button>
          <button className={classes.buttonContainer} onClick={handlePostAndUpload}>Upload Image</button>
          <button className={classes.buttonContainer} onClick={handleCancelChange}>Cancel</button>
        </Box>
      </Box>
      <Typography variant="h5" sx={{ fontWeight: 900, color: '#000', mt: 2 }}>
        {data.userprof.Nickname}
      </Typography>
    </>
  ) : (
    <div className='grid-item'>
      <Typography
        variant="h5"
        sx={{
          fontWeight: 900,
          color: '#fff',
          textShadow: '2px 2px 8px rgba(0, 0, 0, 1)',
        }}
      >
        {userprof.Nickname}
      </Typography>
    </div>
  )}
</Card>


<CardContent sx={{ p: 2, padding: 1, borderRadius: 10 }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 0 }}>
        <CollaboratorsCount userId={userprof._id} />
        
        {/* Conditionally render buttons based on isCollaborating state */}
        {isCollaborating ? (
          <UncollaborateButton
            collaboratorId={userprof._id}
            onClick={handleUncollaborate} // Trigger uncollaborate logic
          />
        ) : (
          <CollaborateButton
            collaboratorId={user.id} 
            collaboratingId={userprof._id}
            onClick={handleCollaborate} // Trigger collaborate logic
          />
        )}
      </Box>
    </CardContent>
          
        <CardContent>
           
          <Typography variant="body2" color="text.secondary">
            {userprof.Koffie_story}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {userprof.Nickname}
          </Typography>
        </CardContent>
        <DialogContent>
          <Typography variant="h6">Selected Card: {selectedCard}</Typography>
          
          <ScrollableContainer>
            {datastuff.map((item) => (
              <ScrollCard key={item.id} onClick={() => handleCardClick(item.id)}>
                <CardContent>
                  <Typography>{item.title}</Typography>
                </CardContent>
              </ScrollCard>
            ))}
          </ScrollableContainer>

 {mydata.map((item) => (
          <Card key={item.id} onClick={() => handleCardClick(item.id)} sx={{p: 1, mb: 2, boxShadow: 5 }}>
            <CardContent sx={{ p: 2, borderRadius: 10 }} >
              <Typography>{item.title}</Typography>
            </CardContent>
            </Card>
          ))}
      
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 0 , mb: 25}}>
            <Card onClick={handleDrawerToggle1} sx={{ width: '100%', p: 2, boxShadow: 5, borderRadius: 4 }}>
              
              Open Drawer
              
              </Card>
            
            </Box>
        </DialogContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 0 }}>
        <AnimatePresence>
  {isButtonVisible && (
    <motion.div
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={buttonVariants}
      style={{ 
        position: 'fixed',
        bottom: 0, 
        right: 16, 
        left: 16, // Add left to stretch to the right
        zIndex: 1300 
      }}
    >
      <Divider sx={{ width: '100vw', position: 'relative', left: '50%', right: '50%', transform: 'translateX(-50%)' }} />
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 1 , backgroundColor: '#fff', width: '100%' }}>
        <Box sx={{ padding: 0, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
          <Typography variant="h6" sx={{padding: 2}}>Hya! {selectedCard}</Typography>
          <Typography variant="body2" sx={{ marginTop: 1 , padding: 2 }}>This is a filler line of text below Hya!</Typography>
        </Box>
        <motion.button
          whileHover="hover"
          variants={buttonVariants}
          style={{
            backgroundColor: '#077336',
            borderRadius: '8px',
            color: 'white',
            border: 'none',
            padding: '10px 20px',
            cursor: 'pointer'
          }}
          onClick={handleDrawerToggle}
        >
          Shop
        </motion.button>
      </DialogActions>
    </motion.div>
  )}
</AnimatePresence>

</Box>

      </Card>
    </div>
    
    </animated.div>

  <Drawer anchor="bottom" open={drawerOpen1} onClose={() => setDrawerOpen1(false)} sx={{ zIndex: 1300 }}>
    <Box sx={{ width: 250, bgcolor: 'background.paper' }}>
        <ArrowBack onClick={handleDrawerToggle1} />
        <Typography variant="h6">Drawer Content</Typography>
        <UserDet />
    </Box>
    </Drawer>

    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
      sx={{
        zIndex: 1300, // Ensure the Drawer appears on top
        width: '100%', // Full width
        maxWidth: '100%', // Prevent Drawer from exceeding screen width
        "& .MuiDrawer-paper": {
          width: '100%', // Ensure the Drawer content also takes up full width
          maxWidth: '100%', // Prevent content from exceeding screen width
        },
      }}
    >
      <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <ArrowBack onClick={handleDrawerToggle} />
        <Typography variant="h6">Drawer Content</Typography>
        <CardMedia
          component="img"
          height="194"
          image={userprof.imageurl}
          alt="Paella dish"
          sx={{ borderRadius: 0 }}
        />
        {userData?.products.map((product) => (
          <Card key={product._id} sx={{ p: 2, mb: 2, boxShadow: 5 }}>
            <CardContent>
              <Typography>{product.name}</Typography>
              <Typography>{product.price}</Typography>
            </CardContent>
          </Card>
        ))}
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2 }}>
          <div style={{ position: 'fixed', bottom: 0, left: 0, zIndex: 1000, mb: 1 }}>
            <Framer />
          </div>
        </Box>
        <Framer />
      </Box>
    </Drawer>
  </Dialog>
            </Box>
          ))}
        </Box>
        
      </Box>
    </div>
    

  );
}
