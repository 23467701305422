import React from 'react';
import { useBag } from '../../context/BagContext';
//import { product } from './Product';
import { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Check, Close } from '@mui/icons-material';
import CheckoutForm from './CheckoutForm';
import AddressForm from './AddressForm';
import { Box, Stack, Card, Typography, Button, CardActionArea, Chip, IconButton, CardMedia,
ListItem, ListItemText, List,
Divider

} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import  apiUrl  from './apiConfig';
import { useNavigate } from 'react-router-dom';
//import Koffieman from './delivery';
import Koffieticket from './prods/coffee';
import Progress from '../mods/progress';
import { makeStyles } from '@mui/styles';
import StoreEquip from './adds/k-equip';
import AiGen2 from './adds/aifeed2';
import { useQuery, gql , useMutation } from '@apollo/client';
import { UserContext } from '../../context/usercontext';
import { useContext } from 'react';


//button style


const useStyles = makeStyles((theme) => ({
  boxContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 0,
    paddingRight: 2,
    borderRadius: 10,
    boxShadow: 10,
    position: 'relative', // Add position relative for absolute positioning of ribbon
   
    
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center', // Centers the button horizontally
    alignItems: 'center', // Centers the button vertically if needed
    width: '60%',
    height: '40px',
    borderRadius: 10,
    backgroundColor: '#077336', // Green color, you can change it as per your theme
    color: 'white',
    border: 'none',
    marginTop: 30,
    fontSize: 16,
    fontWeight: 400,
  },
  logo: {
    width: 40,
    borderRadius: 0,
   
  },
  ribbon: {
    position: 'absolute',
    bottom: 0, // Adjust the position of the ribbon vertically
    left: 20, // Adjust the position of the ribbon horizontally
    backgroundColor: '#d1f0d2', // Adjust the background color of the ribbon
    padding: theme.spacing(.05),
    borderRadius: '5px 5px 5px 0px', // Adjust border radius for the ribbon
    borderWidth: 1, // Adjust border width
    borderStyle: 'solid', // Adjust border style
    borderColor: '#fff', // Adjust border color
    width: 100, // Adjust the width of the ribbon
    
   
  },
  ribbonText: {
    color: 'green', // Adjust the text color of the ribbon
    fontWeight: 300, // Adjust the font weight of the ribbon text
    fontSize: 12, // Adjust the font size of the ribbon text
  },
}));

export const GET_CART = gql`
  query GetCart($userID: ObjectId!) {
    carts(query: { userID: $userID, isPaid: false }) {
      userID
      beanies
      isPaid
      productId
      category
      name
      _id
      price
      imageurl
      quantity
      options {
        size
        color
      }
    }
  }
`;



export const DELETE_ONE_ITEM = gql`
mutation DeleteOneItem($_id: ObjectId!) {
  deleteOneCart(query: {_id: $_id}) {
    _id
  }
}
`;  



const Bag = () => {
  const { user } = useContext(UserContext);
  const classes = useStyles();
  const { itemsInBag , setItemsInBag, } = useBag();
  const [clientSecret, setClientSecret] = useState('');
  const [showCheckout, setShowCheckout] = useState(false);
  const [error, setError] = useState(null);
  const options = { clientSecret };
  const navigate = useNavigate();
  
  
  

const handleUncollaborate = collaboratorId => {
    // ... logic to uncollaborate ...
    console.log(collaboratorId);
};

  const [selectedImageUrl, setSelectedImageUrl] = useState(null);



  const goToStore = () => {
    navigate('/store');
  };

  const { data: cartData, loading: cartLoading, error: cartError } = useQuery(GET_CART, {
    fetchPolicy: 'cache-and-network',
    variables: { userID: user.id , },
    skip: !user.id,
  });
  console.log("cartData:", cartData);

  useEffect(() => {
    if (cartData && cartData.carts) {
      const newItemsInBag = cartData.carts.map((item) => {
        return {
          name: item.name,
          price: item.price,
          imageurl: item.imageurl,
          farmer: item.farmer,
          _id: item._id,
          quantity: item.quantity,
          isPaid: item.isPaid,
          category: item.category,
        };
      });
      setItemsInBag(newItemsInBag);
    }
  }, [cartData, ]);

  
  const [deleteOneItem] = useMutation(DELETE_ONE_ITEM);


  

  
  const stripePromise = loadStripe('pk_live_51NZcvKEc7qSvD2ZQOdT1CysiNDKsWXEsYSkUhY5OKvIkJhJd63fATldzLaPd6QL1t8URtLcG8qwVKcM5Z18JFWaK00Ohha9Ak5'); // Replace with your publishable key

  console.log('Items in bag:', itemsInBag);
  useEffect(() => {
    fetch(`${apiUrl}/create-payment-intent`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ items: itemsInBag }),
     
      
     
    })
    
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          setError('Bag is Empty. Continue shopping.');
          console.log('Items in bag:', itemsInBag);

        } else {
          setClientSecret(data.clientSecret);
        }
      })
      .catch((error) => {
        setError( <Koffieticket/>);
      });
  }, [itemsInBag]);
  
  const handleDeleteItem = (index) => {
    deleteOneItem({ variables: { _id: itemsInBag[index]._id } });
    const newItemsInBag = itemsInBag.filter((item, i) => i !== index);
    setItemsInBag(newItemsInBag);
  };
 
  
  
  

  const handleProceedToCheckout = () => {
    setShowCheckout(true);
  };

//Clear Items in Bag



  // Filter products based on if isPaid is false
  const itemsInBagFiltered = itemsInBag.filter(item => !item.isPaid === true);
  console.log('Items in bag filtered:', itemsInBagFiltered);

  

  const total = itemsInBagFiltered.reduce((sum, item) => {
    // Check if item and item.price exist before adding to the total
    return sum + (item && item.price ? item.price : 0);
  }, 0);

  const shippingCost = total > 49 ? 0 : 6;
  const finalTotal = total + shippingCost;

  const amountToFreeShipping = 44 - total;
  const progress = (total / 44) * 100;
  if (cartLoading) return <div>Loading...</div>;
  if (cartError) return <div>Error: {cartError.message}</div>;
  
  return (
    <div style={{ padding: 8, marginBottom: 50}}>
    {error ? (
      <div>
        <p>{error}</p>
        <button className={classes.buttonContainer} onClick={goToStore}>Continue Shopping</button>
      </div>
    ) : (
      <div>
       <Card sx={{ borderRadius: 0, mt: 2 }}>
  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
    <Typography variant="body1" gutterBottom>
      Shopping Bag
    </Typography>
    <Chip onClick={goToStore} sx={{ backgroundColor: 'transparent', border: ' solid 1px #077336' }} label="Continue Shopping" />
  </Box>
</Card>

      

        {clientSecret ? (
          <Elements options={options} stripe={stripePromise}>
            {!showCheckout ? (
              <>
      {amountToFreeShipping > 0 ? (
          <Typography variant='body1' sx={{ fontWeight: 700 }}>
            ${amountToFreeShipping.toFixed(2)} to free shipping
          </Typography>
        ) : (
          <Typography variant='body1' sx={{ color: '#077336', fontWeight: 700 }}>
            You have free shipping!
          </Typography>
        )}
        <LinearProgress
  variant="determinate"
  value={progress}
  sx={{
    mt: 2,
    mb: 2,
    height: 5, // Adjust the height if needed
    '& .MuiLinearProgress-bar': {
      backgroundColor: 'green', // Bar color
    },
   
  }}
/>

<List>
  {itemsInBagFiltered && itemsInBagFiltered.length > 0 ? (
    itemsInBagFiltered.map((item, index) => (
      <React.Fragment key={index}>
        <ListItem sx={{ display: 'flex', marginBottom: 2, alignItems: 'center' }}>
          <CardMedia
            component="img"
            sx={{ width: 80, height: 80, objectFit: 'contain', borderRadius: 0 }}
            image={item.imageurl}
            alt={item.name}
          />
          <ListItemText
            primary={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="body1" sx={{ marginRight: 2 }}>
                  {item.name}
                </Typography>
                <Typography variant="body1" color="textSecondary" edge='end' fontWeight={600}>
                  ${item.price.toFixed(2)}
                </Typography>
              </Box>
            }
            sx={{ marginLeft: 2 }}
          />
          
          <IconButton onClick={() => handleDeleteItem(index)} edge="end" size="small">
            <Close />
          </IconButton>
        </ListItem>
        
        {/* Conditionally render a divider unless it's the last item */}
        {index < itemsInBagFiltered.length - 1 && <Divider />}
      </React.Fragment>
    ))
  ) : (
    <Typography>No items in the bag.</Typography>
  )}
</List>


                
<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                    <Typography variant="body2" sx={{  fontWeight: 400 }}>
                      Subtotal: ${total.toFixed(2)}
                    </Typography>
                    <Typography variant="body2" sx={{  fontWeight: 400 }}>
                      Shipping: ${shippingCost.toFixed(2)}
                    </Typography>
                    <Typography variant="body2" sx={{  fontWeight: 700 }}>
                      Total: ${finalTotal.toFixed(2)}
                    </Typography>
                  </Box>
                  <button className={classes.buttonContainer} onClick={handleProceedToCheckout}>Proceed to Checkout</button>
                  <Box sx={{}}>
                    <AiGen2/>
               <StoreEquip/>
               </Box>   
              </>
            ) : (
              <Stack spacing={2}>
                <Stack sx={{padding: '30px'}}> <AddressForm /> </Stack>
              <Stack sx={{padding: '30px'}}> <CheckoutForm items={itemsInBag} /></Stack>
             
          </Stack>
              
            )}
            
          </Elements>
        ) : (
          <p><Progress/></p> // Display loading message while fetching client secret
        )}
      </div>
    )}
  </div>
  );
};

export default Bag;

