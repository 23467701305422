import * as React from 'react';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import ContentCut from '@mui/icons-material/ContentCut';
import ContentCopy from '@mui/icons-material/ContentCopy';
import ContentPaste from '@mui/icons-material/ContentPaste';
import Cloud from '@mui/icons-material/Cloud';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Display from '../profile/settings/displaymode';
import LogoutButton from '../profile/logout';
import { Drawer } from '@mui/material';


const useStyles = makeStyles((theme) => ({
    boxContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px',
        marginBottom: '10px',
        },
        logo: {
            width: '50px',
            height: '50px',
            borderRadius: '50%',
            objectFit: 'cover',
            marginBottom: '10px',
            },
            ribbon: {
                position: 'absolute',
                top: '0',
                right: '0',
                backgroundColor: 'red',
                color: 'white',
                padding: '5px 10px',
                fontSize: '0.8rem',
                fontWeight: '700',
                transform: 'translate(50%, -50%) rotate(45deg)',
                zIndex: '1',
                },
                ribbonText: {
                    transform: 'rotate(-45deg)',
                    },
}));


export default function MianMobileMenu() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    
    const ToggleDrawer = () => {
        setOpen(!open);
    }

  return (
    <Drawer anchor="left" open={true}   sx={{ height: '100vh',   }}>
    <Paper sx={{ width: 300, maxWidth: '100%' }}>
      <MenuList>
      <MenuItem>
          <ListItemIcon>
          <Box className={classes.boxContainer}>
      <img src='https://storage.googleapis.com/app_darkendimg/assets/kland.png' alt='menu' className={classes.logo} />
      <Box className={classes.ribbon}>
        <div  className={classes.ribbonText}>
          BETA
        </div>
        
      </Box>
      <Typography variant="body1" color='text.secondary' sx={{fontWeight: 700,  }}>
         KOFFIELAND™
          </Typography>
    </Box>
          
          </ListItemIcon>
         
         
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <ContentCut fontSize="small" />
          </ListItemIcon>
          <ListItemText>World Koffie</ListItemText>
          <Typography variant="body2" color="text.secondary">
            ⌘X
          </Typography>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <ContentCopy fontSize="small" />
          </ListItemIcon>
          <ListItemText>Copy</ListItemText>
          <Typography variant="body2" color="text.secondary">
            ⌘C
          </Typography>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <ContentPaste fontSize="small" />
          </ListItemIcon>
          <ListItemText>Paste</ListItemText>
          <Typography variant="body2" color="text.secondary">
            ⌘V
          </Typography>
        </MenuItem>
        <Divider />
        <MenuItem>
          <ListItemIcon>
            <Cloud fontSize="small" />
          </ListItemIcon>
          <ListItemText>Web Clipboard</ListItemText>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
           <Display />
          </ListItemIcon>
         
        </MenuItem>
      <MenuItem>
                   <ListItemText >
          <Typography variant="h6" color="text.secondary">
            <LogoutButton />
          </Typography>
          </ListItemText>
          
        </MenuItem>
      </MenuList>
    </Paper>
  </Drawer>
  );
}
