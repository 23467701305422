import React from 'react';
import { Card, Grid, Paper, Typography, Box, Avatar , Chip, CardContent, CardMedia} from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
//import congo from '../../images/congobag.png';
import Progress from '../../components/mods/progress';
import { makeStyles } from '@mui/styles';


const GET_PRODUCTS = gql`
  query Products {
    products {
      Title
      _id
      description1
      description2
      imageurl
      name
      price
      category
    }
  }
`;
const useStyles = makeStyles((theme) => ({
    card: {
      display: 'flex',
      width: '100%',
      margin: theme.spacing(1),
      borderRadius: 10,
      boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)', // Optional: add shadow for better depth
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {
      minWidth: 151,
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    chip: {
      margin: theme.spacing(1),
    },
  }));
  
  const ProductsDisplay = () => {
    const classes = useStyles();
    const { loading, error, data } = useQuery(GET_PRODUCTS);

    if (loading) return <div><Progress/></div>;
    if (error) return <div>{null}</div>;
  
    // Group products by category
    const productsByCategory = data.products.reduce((acc, product) => {
      if (!acc[product.category]) {
        acc[product.category] = [];
      }
      acc[product.category].push(product);
      return acc;
    }, {});
  
    const desiredCategories = ['Koffie', 'Equipment']; // Specify desired categories
    
  
    return (
      <Box display="flex" flexWrap="wrap">
       {Object.keys(productsByCategory).map(category => (
        desiredCategories.includes(category) && ( // Render only specified categories
          <div >
            <Typography variant="h4" sx={{fontWeight: 900}}> {category}</Typography>
            <Box key={category} display="flex" flexWrap="wrap">
            
              {productsByCategory[category].map(product => (
                <Box key={product._id} m={1}>
                  <Link to={`/product/${product._id}`}>
                    <Avatar
                      sx={{ border: '5px', borderColor: '#000', width: 80, height: 80 }}
                      alt={product.name}
                      src={product.imageurl}
                      label={product.price}
                    />
                  </Link>
                  <Typography variant="caption">{product.name}</Typography>
                  <Typography variant="body2">${product.price}</Typography>
                </Box>
              ))}
            </Box>
          </div>
        )
      ))}
      </Box>
    );
  };
  
  export default ProductsDisplay;