import React from 'react';
import styled from "styled-components";
import { Avatar, Chip, Fab, Button as MuiButton, Typography, Card, CardActionArea, IconButton, Popover,
CardContent, CardActions, Box
} from '@mui/material';
import { useQuery, gql } from '@apollo/client';
import { Link } from 'react-router-dom'
//import { Store } from '@mui/icons-material';
import  Progress  from '../../mods/progress';
import { CardMedia } from '@mui/material';
import { User } from 'realm-web';
import { useState } from 'react';




const GET_PRODUCTS = gql`
  query Products($isavail: Boolean!) {
    products(query: { isavail: $isavail }) {
      Title
      _id
      description1
      description2
      description4
      imageurl
      name
      price
      farmer
      isavail
      category
    }
  }
`;

export const GET_ROASTERIES = gql`
  query GetImage ($_id: ObjectId!){
    userprofs(query: { _id: $_id }) {
      imageurl
      _id
      Nickname
      Koffie_story
    }
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  
  border-radius: 0px;
  padding: 2px;
  margin: 16px 0;
  
`;

const UsersList = styled.div`
  display: flex;
  overflow-x: auto;
  padding: 1px;
  gap: 8px; // Adjust the gap as needed
  
  // Hide scrollbar for Chrome, Safari and Opera
  &::-webkit-scrollbar {
    display: none;
  }

  // Hide scrollbar for IE, Edge and Firefox
  -ms-overflow-style: none;  // IE and Edge
  scrollbar-width: none;  // Firefox
`;


const UserCard = styled.div`
  
  
  border-radius: 0px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); // This adds a subtle shadow to the card
  text-decoration: none;
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 1px; // Adjust the gap as needed
 
`;

const FollowButton = styled(MuiButton)`
  && {
    margin-top: 8px;
  }
`;

function StoreAdd({ userType="prodman" }) {
  
  const { loading, error, data } = useQuery(GET_PRODUCTS, {
    variables: { isavail: true },
  });

    if (loading) return <div><Progress/></div>;
    if (error) return <div>{null}</div>;
  
    // Group products by category
    const productsByCategory = data.products.reduce((acc, product) => {
      if (!acc[product.category]) {
        acc[product.category] = [];
      }
      acc[product.category].push(product);
      return acc;
    }, {});
  
    const desiredCategories = ['Koffie', ]; // Specify desired categories
   
  
  
  return (
    
    <Container sx={{ fontWeight: 500,  paddingTop: 1, paddingBottom: 1 }} >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingRight: 1, paddingLeft: 1 }}>
        <Typography variant="h6" sx={{ fontWeight: 900, textAlign: 'left', paddingTop: 1, paddingBottom: 1 ,    
      }}>
            Koffie Beans
        </Typography>
        <Link to={`/store/`} style={{textDecoration: 'none', color: 'inherit', textShadow: '4px 4px 8px #f0f0f0', }}>
        <Typography variant="body1" sx={{ color: 'green',  fontStyle: 'italic', fontWeight: 400, textAlign: 'left', paddingTop: 1, paddingBottom: 0 , textShadow: '2px 2px 8px rgba(0, 0, 0, 0.2)',}}>
          View all
        </Typography>
        </Link>
      </Box>
      <UsersList  >                 
      {Object.keys(productsByCategory).map(category => (
  desiredCategories.includes(category) && productsByCategory[category].map(product => (
    <UserCard
  >
   <Link to={`/product/${product._id}`} style={{textDecoration: 'none', color: 'inherit', textShadow: '4px 4px 8px #f0f0f0', }}>
      {/* Content goes here */}
    
    <CardMedia
      component="img"
      width={150}
      height={150}
      image={product.imageurl}
      alt={product.name}
      sx={{ borderRadius: 0,}}
    />
    
    <CardContent 
    
  sx={{ 
   
    padding: 1, 
   
    textAlign: 'center', // Center-align the text
   
  }}
>
<div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', padding: '4px', }}>
  {/* Place text and chip at the bottom */}
  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
  <Typography variant="body2" sx={{ textAlign: 'center', marginTop: '8px', marginRight: 'auto' }}>
    ${product.price}
  </Typography>
  <Typography variant="body2" sx={{ fontWeight: 800, textAlign: 'center', marginBottom: '8px', marginLeft: 'auto' }}>
    {product.name}
  </Typography>
</div>

  {/* Roast Master */}
  <>
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <Typography variant="caption" color="text.secondary" sx={{fontWeight: 700, marginRight: '4px'}}>
      Roast Master
    </Typography>
    <div style={{ marginLeft: 'auto' }}> {/* Pushes the user image to the far right */}
      <UserImage userID={product.description4}  />
    </div>
  </div>

      
    </>
  
  {/* Farmer */}
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <Typography variant="caption" color="text.secondary" sx={{fontWeight: 700, marginRight: '4px'}}>
      Farmer
    </Typography>
    <div style={{ marginLeft: 'auto' }}> {/* Pushes the user image to the far right */}
      <UserImage userID={product.farmer}  />
    </div>
  </div>
  
  
</div>


  </CardContent>
  
  </Link>
  </UserCard>
  
  ))
))}

      </UsersList>
    </Container>
    
 
  );
}

// Component to fetch and display </></> image
function UserImage({ userID }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleChipClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);



  const { loading, error, data } = useQuery(GET_ROASTERIES, {
      variables: { _id: userID },
  });

  if (loading) return <img src="https://storage.googleapis.com/app_darkendimg/assets/farmerbean.png" alt="user" style={{ width: 30, height: 0, borderRadius: 50 }} />;
  if (error) return   <Chip
  label="Coming Soon"
  
  avatar={<Avatar src="https://storage.googleapis.com/app_darkendimg/assets/farmerbean.png" alt="Bean" />}
  variant="outlined"
  style={{ marginTop: 8,}}
/>;

  const userimage = data.userprofs[0]?.imageurl || 'Unknown';
  const username = data.userprofs[0]?.Nickname || 'Unknown';
  const userstory = data.userprofs[0]?.Koffie_story || 'Unknown';

  return (
    <>
    
    <Chip
        label={username}
        onClick={handleChipClick}
        avatar={<Avatar src={userimage} alt={username} />}
      />

<Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div style={{ padding: 16 }}>
          <Typography variant="body1">{userstory}</Typography>
        </div>
      </Popover>
    </>
    
  );
          
}

export default StoreAdd;
