import React, { useContext, useEffect, useState } from 'react'
import Process from '../blockchain/process'
import RoasterSteps from '../blockchain/roaster'
import ProfileBuilder from '../components/profile/profilebuilder'
import SupplyForm from '../blockchain/farmersupply'
import { gql, useQuery, useMutation } from '@apollo/client';
import { Button, Drawer, TextField, Box, Typography, CardMedia, Card, Modal, Grid, Chip, Fab, Divider, Snackbar, CardActions, CardContent, Icon, CardActionArea, useMediaQuery } from '@mui/material';
import { UserContext } from '../context/usercontext';
import Progress from '../components/mods/progress'
import { useItem } from '../components/finance/mangeshop/useitems'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { useFormik } from 'formik';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useProdUpload from '../components/profile/produpload'
import ClaimBeans from '../components/mods/dialogue/claimbeans'
import { ArrowBack } from '@mui/icons-material'
//import AddItem from '../components/finance/mangeshop/additem'
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import upload from '../images/upload.png'
import  useFileUpload  from '../components/profile/cloud'
import { useParams, Link } from 'react-router-dom'
import coffeebeans from '../images/coffeebeans.png'
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Collapse from '@mui/material/Collapse';
import CircularProgress from '@mui/material/CircularProgress';
import beanbag from '../images/beanbag.png'
import Akup from '../components/mods/cssimages/akup'
import Welcomekland from '../components/mods/snacks/koffieland'
//import RedeemCoffee from '../components/finance/redeemcoffee'
import { makeStyles  } from '@mui/styles'
import { useSpring , animated} from 'react-spring'
import BeanCard from '../components/mods/beancard'
import AiGen2 from '../components/finance/adds/aifeed2'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import CloseIcon from '@mui/icons-material/Close';
import useTheme from '@mui/material/styles/useTheme';
import AddIcon from '@mui/icons-material/Add';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center', // Centers the button horizontally
    alignItems: 'center', // Centers the button vertically if needed
    width: '50%',
    height: '40px',
    borderRadius: 20,
    backgroundColor: '#077336', // Green color, you can change it as per your theme
    color: 'white',
    border: 'none',
    marginTop: 30,
    fontSize: 20,
    fontWeight: 700,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 10,
    minWidth: 400,
    boxShadow: 'none',
  },
}));


const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));



const KUPSTER_BY_USERID = gql`
  query KupsterByUserID($userID: ObjectId!) {
    kupster(query: { userID: $userID }) {
      Title
      _id
      favorite_koffie
      userID
      
    }
  }
`;
const CREATE_KUPSTER = gql`
  mutation CreateKupster($data: KupsterInsertInput!) {
    insertOneKupster(data: $data) {
      _id
      favorite_koffie
      Title
      userID
    }
  }
`;

const UPDATE_ONE_KUPSTER = gql`
  mutation UpdateUserprof($query: KupsterQueryInput!, $set: KupsterUpdateInput!) {
    updateOneKupster(query: $query, set: $set) {
      _id
      favorite_koffie
      Title
      userID
      
      
    }
  }
`;

const GET_BEAN_COUNT = gql`
  query GetBeanCount($_id: ObjectId!) {
    userprof(query: {_id: $_id}) {
      beanCount
      _id
    }
  }
`;

const DECREMENT_BEAN_COUNT = gql`
  mutation DecrementBeanCount($_id: ObjectId!, $beanCount_inc: Int!) {
    updateOneUserprof(query: { _id: $_id }, set: { beanCount_inc: $beanCount_inc }) {
      _id
      beanCount
    }
  }
`;

const MY_ADDS = gql`
  query MyAdds ($userID: String!) {
    aiadds (query: {userID: $userID}) {
    Title
		When
		_id
		description
		price
		primaryImage
		productID
		userID
    beanamount
  }
}
  
`; 

const UPDATE_ISADD = gql`
  mutation UpdateOneAiadd($query: AiaddQueryInput!, $set: AiaddUpdateInput!) {
    updateOneAiadd(query: $query, set: $set) {
      _id
      isAdd
      # Include other fields you might want to return
    }
  }
`;

const DELETE_ONE_AIADD = gql`
  mutation DeleteOneAiadd($query: AiaddQueryInput!) {
    deleteOneAiadd(query: $query) {
      _id
      
    }
  }
`;


const GET_LIKES_BY_CONTENT_CREATOR = gql`
  query GetLikesByContentCreator($contentCreator: String!) {
    likes(query: { contentCreator: $contentCreator }) {
      _id
      contentId
      contentType
      user
      contentCreator
    }
  }
`;

const GET_COMMENTS_BY_CONTENT_CREATOR = gql`
  query GetCommentsByContentCreator($contentCreator: String!) {
    comments(query: { contentCreator: $contentCreator }) {
      _id
      contentId
      contentType
      author
      contentCreator
      content
    }
  }
`;

function KupsterDash() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const {user} = useContext(UserContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDrawerOpen1, setIsDrawerOpen1] = useState(false);
  const { __id } = useParams();
  const [expanded, setExpanded] = React.useState(false);
 const { Nickname } = user.customData;
 const [expandedIndex, setExpandedIndex] = useState(null);

  
  const classes = useStyles();
 
  const { loading, error, data, refetch } = useQuery(KUPSTER_BY_USERID, {
    variables: { userID: user ? user.id : null },
  });
   // Ensure that the user ID is available
  useEffect(() => {
    console.log("User from context:", user);
  }, [user]);

  const { loading: loadingLikes, error: errorLikes, data: likesData } = useQuery(GET_LIKES_BY_CONTENT_CREATOR, {
    variables: { contentCreator: user?.id }, // Assuming user.id is a string
  });
 // likes count
  const likesCount = likesData?.likes.length || 0;

  // comments count
  const { loading: loadingComments, error: errorComments, data: commentsData } = useQuery(GET_COMMENTS_BY_CONTENT_CREATOR, {
    variables: { contentCreator: user?.id }, // Assuming user.id is a string
  });

  if (errorComments) {
    console.error("Error fetching comments:", errorComments.message);
  }
  const commentsCount = commentsData?.comments.length || 0;

  const { file, previewURL, handleFileChange, uploadFile, handleRemoveImage } = useFileUpload(); // Use your custom hook
    const [showDialog, setShowDialog] = useState(false);
    const [beanCount, setBeanCount] = useState(0); // Initialize with 0 beans
    const [postSubmitted, setPostSubmitted] = useState(false); // Added state for postSubmitted
    const [isUploading, setIsUploading] = useState(false);
    const fileInputRef = React.createRef();
    const { data: beanData, loading: beanLoading, error: beanError } = useQuery(GET_BEAN_COUNT, {
      variables: { _id: user.id },
    });
    const [decrementBeanCount] = useMutation(DECREMENT_BEAN_COUNT);
    const [updateKupster] = useMutation(UPDATE_ONE_KUPSTER);
    const { data: aiAdds, loading: aiLoading, error: aiError } = useQuery(MY_ADDS,
      {variables: { userID: user ? user.id : null } });
      const [updateIsAdd] = useMutation(UPDATE_ISADD);
      const [message, setMessage] = useState('');
  
      //Delete one Add
  const [deleteOneAiadd] = useMutation(DELETE_ONE_AIADD,
    { refetchQueries: [{ query: MY_ADDS, variables: { userID: user ? user.id : null } }] });
    
    const handlePhotoClick = () => {
      fileInputRef.current.click();
    };
 
    const handleDeleteAdd = async (item) => {
      try {
        const { data } = await deleteOneAiadd({
          variables: {
            query: { _id: item._id },
          },
        });
  
        if (data.deleteOneAiadd) {
          setMessage(`Deleted: ${data.deleteOneAiadd.name}`);
          // Optionally, update local state or refetch queries here
        }
      } catch (error) {
        setMessage(`Error: ${error.message}`);
      }
    };
  



  // Set the Add to ture
  const handleUpdateIsAdd = (_id) => {
    updateIsAdd({
      variables: {
        query: { _id: _id }, // Specify which document to update
        set: { isAdd: true } // Set isAdd to true
      }
    })
    if (beanCount < 1) {
      setShowDialog(true); // This will automatically show the dialog
      return;
    }
    decrementBeanCount({
      variables: { _id: user.id, beanCount_inc: -200 }
    })

    .then(response => {
      console.log('Update successful', response);
      // Perform any actions after successful update, e.g., refresh data or UI
    })
    .catch(error => {
      console.error('Error updating isAdd', error);
    });
  };
    

    useEffect(() => {
        if (beanData && beanData.userprof) {
            setBeanCount(beanData.userprof.beanCount);
        }
    }, [beanData]);

    const handleExpandClick = (index) => {
      setExpandedIndex(expandedIndex === index ? null : index);
    };
    

    const [bounce, setBounce] = useState(false);
    const [bounceCount, setBounceCount] = useState(0);
    const bounceAnimation = useSpring({
      transform: bounce
        ? 'translateY(-10px) scale(1.1)'
        : 'translateY(0px) scale(1)',
      config: { duration: 2000 },
    });
  
    useEffect(() => {
      const interval = setInterval(() => {
        if (bounceCount < 50) {
          setBounce((prevState) => !prevState);
          setBounceCount((prevState) => prevState + 1);
        } else {
          clearInterval(interval);
          setTimeout(() => {
            setBounceCount(0);
            interval && setInterval(() => {
              setBounce((prevState) => !prevState);
              setBounceCount((prevState) => prevState + 5);
            }, 2500);
          }, 1000);
        }
      }, 2500);
  
      return () => {
        clearInterval(interval);
      };
    }, [bounceCount]);
  const [editProduct, setEditProduct] = useState(null);  // For storing product being edited
  const [openEditDialog, setOpenEditDialog] = useState(false);  // For controlling edit dialog
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);  // For controlling delete dialog
  const { products, handleInsertProduct, handleUpdateProduct, handleDeleteProduct } = useItem();
  const [createKupster] = useMutation(CREATE_KUPSTER,
    {
      refetchQueries: [{ query: KUPSTER_BY_USERID, variables: { userID: user ? user.id : null } }],
      variables: { _id: user.id },
    });

  const handleDrawerOpen1 = () => {
    setIsDrawerOpen(true);
  };
  
  const handleDrawerClose1 = () => {
    setIsDrawerOpen(false);
  };
  const query = { userID: user ? user.id : null }; // assuming you identify users by an _id

  const dataExists = data && data.kupster && data.kupster.userID; // Example condition
  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
  
    // Prepare the input data
    const input = {
      flavor_profile: formData.get('Title'),
      favorite_koffie: formData.get('favorite_koffie')
     
    };
  
    // Check if the data exists (you need a way to determine this)
    if (dataExists) { // Implement your logic to determine if data exists
      // Update the profile
      updateKupster({ variables: { data } })
        .then(response => {
          console.log('Profile Updated:', response.data);
          handleDrawerClose();
        })
        .catch(error => {
          console.error('Could not update profile:', error);
        });
    } else {
      createKupster({ variables: { data } })
        .then(response => {
          console.log('New Profile Created:', response.data);
          handleDrawerClose();
        })
        .catch(error => {
          console.error('Could not create profile:', error);
        });
    }
  };
  
  // Drawer with Form
  const renderDrawer = () => (
    <Drawer anchor="right" open={isDrawerOpen} onClose={handleDrawerClose1}>
      <Box
        sx={{ width: '100%', padding: 3, backgroundColor: '#fff'}}
        role="presentation"
        component="form"
        noValidate
        onSubmit={handleSubmit}
      >
        <IconButton onClick={handleDrawerClose1}>
          <ArrowBack />
        </IconButton>
        <Card sx={{ padding: 3, marginBottom: 2 }}>
        <Typography variant="h4" component="h2"fontWeight={500} color='#e39024'>
          Coming Soon!
          </Typography>
          </Card>
        <TextField label="Title" name="Title" fullWidth />
        <TextField label="Favorite Koffie" name="favorite_koffie" fullWidth />

        <Button type="submit">Save Settings</Button>
      </Box>
    </Drawer>
  );

  

  const startEditing = (product) => {
    setEditProduct(product);
    setOpenEditDialog(true);
  };

  const startDeleting = (product) => {
    setEditProduct(product);
    setOpenDeleteDialog(true);
  };

  const handleEdit = () => {
    // Call your update function here, e.g.
    handleUpdateProduct(editProduct);
    setOpenEditDialog(false);
  };

  const handleDelete = () => {
    // Call your delete function here, e.g.
    handleDeleteProduct(editProduct._id);
    setOpenDeleteDialog(false);
  };


 
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  

 // Function to determine cols and rows based on some condition, you can adjust this logic
 const getColsAndRows = (product) => {
  // You could replace this with your actual logic to determine featured products
  return product.featured ? { cols: 2, rows: 2 } : { cols: 1, rows: 1 };
};

const handleOpenDialog = () => {
  setOpenDialog(true);
};

const handleCloseDialog = () => {
  setOpenDialog(false);
  
};

const handleCloseSnackbar = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setOpenSnackbar(false);
};



 const formik = useFormik({
  initialValues: {
    productName: '',
    productDescription: '',
    productPrice: '',
    beanamount: '',
    // productImage: '', // This can be removed if you're not using it
  },
  onSubmit: async (values, { resetForm }) => {
    // Directly call uploadFile function to upload the image
   

    if (beanCount < 1) {
      setShowDialog(true); // This will automatically show the dialog
     
      return;
    }

    setIsUploading(false); // End the upload process
    await decrementBeanCount({
      variables: { _id: user.id, beanCount_inc: -50 }
    });
    const uploadedImageURL = await uploadFile(file, user.id);
   
    // Check if the upload was successful
    if (!uploadedImageURL) {
      console.error('File upload failed: no image URL returned');
      handleCloseDialog();
      return;
    }
    
   
  
    // Decrement beanCount on the client side for immediate feedback
    setBeanCount(beanCount - 50);
    
    // Create the new product item with the uploaded image URL
    const newProduct = {
      name: values.productName,
      description1: values.productDescription,
      price: values.productPrice,
      imageurl: uploadedImageURL, // Use the uploaded image URL here
      userID: user.id, // Make sure this is the correct way to access the user's ID
    };
    
    // Insert the product item
    handleInsertProduct(newProduct);

    // Reset the form and close any modals/drawers
    resetForm(); // You can use the formik bag's resetForm function
    handleCloseDialog();
  },
  // ... You can add validationSchema or validate function if needed
});

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };
  
  useEffect(() => {
    if (user && user.user && user.user.id) {
      // Trigger refetch or some other action
    }
  }, [user]);
  
  
    
  
  const handleFabClick = () => {
    fileInputRef.current.click();
};




  // Add this line to handle errors
  if (error) return <div>Error: {error.message}</div>;

  if (loading) return <div><Progress/></div>;
  
  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      
     <Box sx={{ width: '100%', paddingTop: 2, paddingBottom: 2 }}>
      
    
      
      
     <Grid container spacing={2} justifyContent="center" alignItems="stretch">
  <Grid item xs={12} md={12}>
    
     <BeanCard />
    
  </Grid>
</Grid>

     
    </Box>
     
      {data && data.kupster ? (
        Array.isArray(data.kupster) ? (
          data.kupster.map((item, index) => (
            <div key={index}>
              {item.favorite_koffie ? item.favorite_koffie : "No Favorite"}
              {item.Title ? item.Title : "No Title"}
            </div>
          ))
        ) : (
          <Card raised style={{ textAlign: 'center', borderRadius: 5 }}>
            {data.kupster.favorite_koffie ? data.kupster.favorite_koffie : "No Favorite"}
            {data.kupster.Title ? data.kupster.Title : "No Title"}
          </Card>
        )
      ) : (
        <Card  raised  sx={{ textAlign: 'center', borderRadius: 5, backgroundColor: '#009dd1' }}>
          <CardContent ax={{backgroundColor: '#009dd1'}}>
          <Typography variant="body2" gutterBottom>
            Options
          </Typography>
          <Typography variant="subtitle1">
            Set up your custom settings for a better experience
          </Typography>
          <Chip 
  label="Customize Settings"  
  style={{ margin: '10px 0' }} 
  onClick={handleDrawerOpen1} 
/>
          </CardContent>
        </Card>
      )}
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
      {!isMobile && (
     <Modal open={drawerOpen} onClose={handleDrawerClose1}sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center',  }}>
      <Box sx={{ maxWidth: 400, padding: 3, backgroundColor: '#fff', border: 'solid 1px #000'}}
        role="presentation"
        component="form"
        noValidate
        onSubmit={handleSubmit}
      >
         <Box sx={{ width: '100%', padding: 0 }}>
        <Box display="flex" alignItems="center">
      <IconButton onClick={handleDrawerClose}>
        <ArrowBack />
      </IconButton>
      <Typography variant="h6" sx={{fontWeight: 600, margin: 0}} >
              {Nickname}'s Collection 
            
            </Typography>
    </Box>
          
    
          <Box sx={{ display: 'flex', padding: 1,justifyContent: 'center', alignItems: 'center' }}>
          
          <Card sx={{ padding: 2, marginBottom: 3, borderRadius: 5, boxShadow: 0 }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img 
          src='https://storage.googleapis.com/app_darkendimg/newton.png' 
          alt={user.name} 
          width={40} 
          style={{ opacity: 0.9, borderRadius: '50%' }} 
        />
      </Box>
      <CardContent sx={{ textAlign: 'center', borderRadius: 5, border: 'solid 1px #d1d1d1', marginTop: 2 }}>
        <Typography variant="body2" sx={{ fontWeight: 600, color: 'text.secondary' }}>
          In Koffieland, the economy thrives on artisanal craftsmanship. Each product stands out with its unique character and quality, embodying the irreplaceable touch of human hands. As the world moves towards automation, the authentic value of our artisanal goods only grows, marking them as treasures in an age of mass production.
        </Typography>
      </CardContent>
      <CardActions sx={{ justifyContent: 'center', marginTop: 2 }}>
        <CardActionArea onClick={handleOpenDialog}>
          <Fab color="primary" aria-label="add" sx={{ backgroundColor: '#077336', color: '#fff' }}>
            <AddIcon />
          </Fab>
        </CardActionArea>
      </CardActions>
    </Card>
      
          <Dialog  open={openDialog} onClose={handleCloseDialog}>
          <div style={{ display: 'flex', alignItems: 'flex-start', padding: 1 }}>
  <IconButton onClick={handleCloseDialog} aria-label="Go back">
    <ArrowBack />
  </IconButton>
</div>

<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 1 }}>
  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' , padding: 2 }}>
    <Typography variant='h5' sx={{ fontWeight: 600 }}>Add Product</Typography>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      X50 <img src='https://storage.googleapis.com/app_darkendimg/lilbeanie.png' alt='beans' width={30} /> beans
    </Box>
  </Box>
</Box>

          <form className={classes.form} onSubmit={formik.handleSubmit}>
          
          <Grid item>
              {previewURL ? (
                <Box sx={{ position: 'relative', width: 100, height: 100 }}>
                  <CardMedia
                    component="img"
                    image={previewURL}
                    alt="Uploaded Image Preview"
                    sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                 <IconButton
        onClick={handleRemoveImage}
        sx={{
          position: 'absolute', top: 0, right: 0, color: 'red', backgroundColor: 'white', '&:hover': {
            backgroundColor: '#ffcccc',
          }
        }}
        size="small"
      >
        <CloseIcon fontSize="small" />
      </IconButton>
                </Box>
              ) : (
                <Box
                  sx={{
                    width: 100, height: 100, display: 'flex', alignItems: 'center', justifyContent: 'center',
                    backgroundColor: '#eee', cursor: 'pointer', borderRadius: 1, '&:hover': {
                      backgroundColor: '#f0f0f0',
                    }
                  }}
                  onClick={handlePhotoClick}
                >
                  <AddAPhotoIcon
                    sx={{
                      fontSize: 40,
                      color: '#71c584',
                      '&:hover': {
                        color: '#4f9d69',
                      }
                    }}
                  />
                </Box>
              )}
            </Grid>
         
           {/* Image preview */}
      
      {/* File input */}
      
      <input 
        type="file" 
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
       
         
            <TextField
              label="Product Name"
              variant="outlined"
              fullWidth
              margin="normal"
              name="productName"
              onChange={formik.handleChange}
              value={formik.values.productName}
            />
           
           
            
            <TextField
              label="Product Description"
              variant="outlined"
              fullWidth
              margin="normal"
              name="productDescription"
              onChange={formik.handleChange}
              value={formik.values.productDescription}
            />
            <TextField
  label="Product Price"
  variant="outlined"
  fullWidth
  margin="normal"
  name="productPrice"
  onChange={formik.handleChange}
  value={formik.values.productPrice}
  type="number" // HTML5 input type that allows only numerical input
  InputLabelProps={{
    shrink: true, // This helps manage the label when the type is 'number'
  }}
/>
          {showDialog && <ClaimBeans onClose={() => setShowDialog(false)} />}
           
            <button 
            variant="contained"
          
              className={classes.buttonContainer}
            >
              Add Item
            </button>
           
             
            {isUploading && <CircularProgress />}
          </form>
         <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: 2 }}>
          
          <Typography variant="h6" sx={{ fontWeight: 400, textAlign: 'center', margin: 2 }}>
                      Let our AI Smart Feed help you sell your products
          </Typography>
          <Divider variant='middle'  />
          </Box>
          <AiGen2 />
          </Dialog>
         
          <Snackbar
        open={openSnackbar}
        autoHideDuration={1000}
        onClose={handleCloseSnackbar}
        message="Item added successfully"
      />
          </Box>
        </Box>
        
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
         
        
        <Divider variant='middle'  />
      {/* Edit Product Dialog */}
      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
        <DialogTitle>Edit Product</DialogTitle>
        <DialogContent>
          {/* Place your form fields for editing here, e.g., */}
          <TextField
    label="Product Name"
    fullWidth
    value={editProduct?.name || ''}
    onChange={(e) => setEditProduct({ ...editProduct, name: e.target.value })}
    sx={{ mb: 2, mt: 2 }}  
  />
  <TextField
    label="Product Price"
    fullWidth
    value={editProduct?.price || ''}
    onChange={(e) => setEditProduct({ ...editProduct, price: e.target.value })}
    sx={{ mb: 2 }} 
  />
  <TextField
    label="Product Description"
    fullWidth
    value={editProduct?.description1 || ''}
    onChange={(e) => setEditProduct({ ...editProduct, description1: e.target.value })}
    sx={{ mb: 2 }}  
  />
        
          {/* Add more fields as needed */}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditDialog(false)} sx={{backgroundColor: '#077336', borderRadius: 5, color: '#fff', border: 'none'}}>Cancel</Button>
          <Button onClick={handleEdit} sx={{backgroundColor: '#077336', borderRadius: 5, color: '#fff', border: 'none'}}>Save</Button>
        </DialogActions>
      </Dialog>

      {/* Delete Product Dialog */}
      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this product?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
          <Button onClick={handleDelete}>Delete</Button>
        </DialogActions>
      </Dialog>

      <div>
  {products.length > 0 ? (
    <List>
      {products.map((product, index) => (
        <Card key={index} sx={{ padding: 0, marginBottom: 2 }}>
          <ListItem>
            <ListItemAvatar>
              <Avatar variant="rounded">
                <img src={product.imageurl} alt={product.name} width={40}/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={product.name}
              secondary={product.description1}
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="edit" onClick={() => startEditing(product)}>
                <EditIcon />
              </IconButton>
              <IconButton edge="end" aria-label="delete" onClick={() => startDeleting(product)}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </Card>
      ))}
    </List>
  ) : (
    <Card sx={{ padding: 2, margin: 2, textAlign: 'center' }}>
      <Typography variant="h6" color="text.secondary">
        You have no items to edit
      </Typography>
    </Card>
  )}
</div>
        </div>
        </Box>
      </Modal>

    )}
    </div>
    
   <div>
   {isMobile && (
          <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={handleDrawerClose}
        sx={{ width: "100%" }}
      >
        <Box sx={{ width: '100%', padding: 0 }}>
        <Box display="flex" alignItems="center">
      <IconButton onClick={handleDrawerClose}>
        <ArrowBack />
      </IconButton>
      <Typography variant="h6" sx={{fontWeight: 600, margin: 0}} >
              {Nickname}'s Collection 
            
            </Typography>
    </Box>
          
    
          <Box sx={{ display: 'flex', padding: 1,justifyContent: 'center', alignItems: 'center' }}>
          
          <Card sx={{ padding: 1, marginBottom: 2, borderRadius: 5, justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
           
            <img src='https://storage.googleapis.com/app_darkendimg/newton.png' alt={user.name} width={100} style={{ opacity: 0.9 }} />         
          </Box>
            <CardContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' , borderRadius: 5, border:'solid 2px #d1d1d1' }}>
            <Typography variant="body2" gutterBottom sx={{fontWeight: 600, color: 'text.secondary'}}>
  In Koffieland, the economy thrives on artisanal craftsmanship. Each product stands out with its unique character and quality, embodying the irreplaceable touch of human hands. As the world moves towards automation, the authentic value of our artisanal goods only grows, marking them as treasures in an age of mass production.
</Typography>

              </CardContent>
              <CardActions>
            <CardActionArea onClick={handleOpenDialog} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          
            <Fab icon={<Icon icon="add" />}  aria-label="add" sx={{ backgroundColor: '#077336', color: '#fff' }} >
              Add
            </Fab>
            </CardActionArea>
            </CardActions>
       
      </Card>
      
          <Dialog fullScreen open={openDialog} onClose={handleCloseDialog}>
          <div style={{ display: 'flex', alignItems: 'flex-start', padding: 1 }}>
  <IconButton onClick={handleCloseDialog} aria-label="Go back">
    <ArrowBack />
  </IconButton>
</div>

<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 1 }}>
  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' , padding: 2 }}>
    <Typography variant='h5' sx={{ fontWeight: 600 }}>Add Product</Typography>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      X50 <img src='https://storage.googleapis.com/app_darkendimg/lilbeanie.png' alt='beans' width={30} /> beans
    </Box>
  </Box>
</Box>

          <form className={classes.form} onSubmit={formik.handleSubmit}>
          
          <Grid item>
              {previewURL ? (
                <Box sx={{ position: 'relative', width: 100, height: 100 }}>
                  <CardMedia
                    component="img"
                    image={previewURL}
                    alt="Uploaded Image Preview"
                    sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  />
                 <IconButton
        onClick={handleRemoveImage}
        sx={{
          position: 'absolute', top: 0, right: 0, color: 'red', backgroundColor: 'white', '&:hover': {
            backgroundColor: '#ffcccc',
          }
        }}
        size="small"
      >
        <CloseIcon fontSize="small" />
      </IconButton>
                </Box>
              ) : (
                <Box
                  sx={{
                    width: 100, height: 100, display: 'flex', alignItems: 'center', justifyContent: 'center',
                    backgroundColor: '#eee', cursor: 'pointer', borderRadius: 1, '&:hover': {
                      backgroundColor: '#f0f0f0',
                    }
                  }}
                  onClick={handlePhotoClick}
                >
                  <AddAPhotoIcon
                    sx={{
                      fontSize: 40,
                      color: '#71c584',
                      '&:hover': {
                        color: '#4f9d69',
                      }
                    }}
                  />
                </Box>
              )}
            </Grid>
         
           {/* Image preview */}
      
      {/* File input */}
      
      <input 
        type="file" 
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
       
         
            <TextField
              label="Product Name"
              variant="outlined"
              fullWidth
              margin="normal"
              name="productName"
              onChange={formik.handleChange}
              value={formik.values.productName}
            />
           
           
            
            <TextField
              label="Product Description"
              variant="outlined"
              fullWidth
              margin="normal"
              name="productDescription"
              onChange={formik.handleChange}
              value={formik.values.productDescription}
            />
            <TextField
  label="Product Price"
  variant="outlined"
  fullWidth
  margin="normal"
  name="productPrice"
  onChange={formik.handleChange}
  value={formik.values.productPrice}
  type="number" // HTML5 input type that allows only numerical input
  InputLabelProps={{
    shrink: true, // This helps manage the label when the type is 'number'
  }}
/>
          {showDialog && <ClaimBeans onClose={() => setShowDialog(false)} />}
           
            <button 
            variant="contained"
          
              className={classes.buttonContainer}
            >
              Add Item
            </button>
           
             
            {isUploading && <CircularProgress />}
          </form>
         <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: 2 }}>
          
          <Typography variant="h6" sx={{ fontWeight: 400, textAlign: 'center', margin: 2 }}>
                      Let our AI Smart Feed help you sell your products
          </Typography>
          <Divider variant='middle'  />
          </Box>
          <AiGen2 />
          </Dialog>
         
          <Snackbar
        open={openSnackbar}
        autoHideDuration={1000}
        onClose={handleCloseSnackbar}
        message="Item added successfully"
      />
          </Box>
        </Box>
        
        <div>
         
        
        <Divider variant='middle'  />
      {/* Edit Product Dialog */}
      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
        <DialogTitle>Edit Product</DialogTitle>
        <DialogContent sx={{ padding: 2 ,}}>
          {/* Place your form fields for editing here, e.g., */}
          <TextField
    label="Product Name"
    fullWidth
    value={editProduct?.name || ''}
    onChange={(e) => setEditProduct({ ...editProduct, name: e.target.value })}
    sx={{ mb: 2, mt: 2 }}  
  />
  <TextField
    label="Product Price"
    fullWidth
    value={editProduct?.price || ''}
    onChange={(e) => setEditProduct({ ...editProduct, price: e.target.value })}
    sx={{ mb: 2 }} 
  />
  <TextField
    label="Product Description"
    fullWidth
    value={editProduct?.description1 || ''}
    onChange={(e) => setEditProduct({ ...editProduct, description1: e.target.value })}
    sx={{ mb: 2 }}  
  />
         
          {/* Add more fields as needed */}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditDialog(false)} sx={{backgroundColor: '#077336', borderRadius: 5, color: '#fff', border: 'none'}}>Cancel</Button>
          <Button onClick={handleEdit} sx={{backgroundColor: '#077336', borderRadius: 5, color: '#fff', border: 'none'}}>Save</Button>
        </DialogActions>
      </Dialog>

      {/* Delete Product Dialog */}
      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this product?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
          <Button onClick={handleDelete}>Delete</Button>
        </DialogActions>
      </Dialog>

      <div>
  {products.length > 0 ? (
    <List>
      {products.map((product, index) => (
        <Card key={index} sx={{ padding: 0, marginBottom: 2 }}>
          <ListItem>
            <ListItemAvatar>
              <Avatar variant="rounded">
                <img src={product.imageurl} alt={product.name} width={40}/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={product.name}
              secondary={product.description1}
            />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="edit" onClick={() => startEditing(product)}>
                <EditIcon />
              </IconButton>
              <IconButton edge="end" aria-label="delete" onClick={() => startDeleting(product)}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </Card>
      ))}
    </List>
  ) : (
    <Card sx={{ padding: 2, margin: 2, textAlign: 'center' }}>
      <Typography variant="h6" color="text.secondary">
        You have no items to edit
      </Typography>
    </Card>
  )}
</div>

      
    </div>
      </Drawer>
)}
</div>
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: 1}}>
   
    {products.length > 0 ? (
      <>
       <Card sx={{ 
   
   width: '100%',
   display: 'flex', 
   alignItems: 'left', 
   justifyContent: 'space-between', 
   padding: '0 10px',
   borderRadius: 5,
   mt: 2,
    mb: 2,
    
   
 }}>
   <Typography variant="h6" sx={{ fontWeight: 700}}>
     Manage Gallery 
   </Typography>
   
   <Chip
     label="Manage Shop"
     onClick={handleDrawerOpen}
     
     style={{ cursor: 'pointer', backgroundColor: '#2fa849', color: '#fff' }}
   />
 </Card>
    <ImageList
  sx={{
   
    overflowY: 'auto', // Allows scrolling within the ImageList if needed
    boxShadow: 0,
    borderRadius: 5,
    padding: 2,
    bgcolor: '#transparent',
  }}
  rowHeight={100}
  gap={1}
  cols={4}
  
>
  

      {products.map((product) => {
        const { cols, rows } = getColsAndRows(product);

        return (
          <Link to={`/product/${product._id}`} key={product._id}>
          <ImageListItem variant key={product.id} cols={cols} rows={rows}
          sx={{}}
          >
            <img
              src={product.imageurl}
              alt={product.name}
              loading="lazy"
              style={{ height: '100%', width: '100%', objectFit: 'cover', borderRadius: 15, padding: 5 }}
            />
            <ImageListItemBar
            sx={{background: 'none'}}
              title={product.name}
              subtitle={'$' + product.price}
              position="bottom"
             
            />

          </ImageListItem>
          </Link>
        );
      })}
    </ImageList>
    </>
    ) : (
      // Display a Card instead of a div for the message
      <Box sx={{ mt: 0, mb: 2, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: 4, bgcolor: '#00cc99', borderRadius: 5 }}>
          <Typography variant="h6" color="white"fontWeight={300}>
            Stimulate the economy with your artisanal goods and services
          </Typography>
          <Typography variant="body1">
            Let the AI smart cart sell for you.
          </Typography>
          
            <button
             onClick={handleDrawerOpen}
              className={classes.buttonContainer}
            >
              Start Selling
            </button>
            
          
        </Box>
    )}
    </div>
    
    
   
      <Card sx={{
        height: 'auto', // Changed from '10vh' to 'auto' to accommodate the list
        width: '100%',
        display: 'flex',
        flexDirection: 'column', // Changed to 'column' to stack items vertically
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: 5,
       
        
      }}>
         <CardMedia sx={{ textAlign: 'center', width: '100%', padding: 0, mt: 2, mb: 2, bgcolor: 'transparent',  }}>
            <Typography variant="h6" gutterBottom sx={{fontWeight:700, textAlign: 'left', }}>
            Add generated add to live feed/market
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ textAlign: 'left', }}>
            The AI smart cart has generated some smart sales for you. Add them to the live feed to generate user interest.
            </Typography>
          </CardMedia>
          {aiAdds && aiAdds.aiadds.length > 0 ? (
  Array.isArray(aiAdds.aiadds) ? (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: 20 , padding: 1}}>
      {aiAdds.aiadds.map((item, index) => (
        <Card key={index} sx={{ width: '100%', flexDirection: 'column', boxShadow: 3, borderRadius: 5, bgcolor: '#f9f9f9', padding: 1}}>
          <CardMedia
            component="img"
            height="150"
            image={item.primaryImage}
            alt={item.Title ? item.Title : "No Title"}
            style={{ objectFit: 'cover', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}
          />
          <CardContent>
            <Typography variant="h6" gutterBottom>
              {item.Title ? item.Title : "No Title"}
            </Typography>
            <Typography variant="body1" color='textSecondary'
            sx={{ 
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 1, /* number of lines to show */
              WebkitBoxOrient: 'vertical'
            }}
            >
              {item.description ? item.description : "No Description"}
            </Typography>
          </CardContent>
          <CardActions>
            <Chip label="Add to Market" onClick={() => handleUpdateIsAdd(item._id)} />
            <Chip label="Remove" onClick={() => handleDeleteAdd(item)} />
            <Box sx={{ flexGrow: 1 }} />
            <Chip
              label="500"
              avatar={<Avatar src={coffeebeans} alt="Bean" />}
              variant="outlined"
            />

            <IconButton
              onClick={() => handleExpandClick(index)}
              aria-expanded={expandedIndex === index}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </CardActions>
          <Collapse in={expandedIndex === index} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography paragraph>
                {item.description ? item.description : "No Description"}
              </Typography>
            </CardContent>
          </Collapse>
        </Card>
      ))}
    </div>
  ) : (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Card sx={{ maxWidth: 400 }}>
        <CardContent>
          <Typography variant="body1">
            {aiAdds.aiadds.description ? aiAdds.aiadds.description : "No Description"}
          </Typography>
        </CardContent>
        <CardActions>
          <Typography variant="body1">
            {aiAdds.aiadds.price ? aiAdds.aiadds.price: "No Price"}
          </Typography>
        </CardActions>
      </Card>
    </div>
  )
) : (
  <Card sx={{ textAlign: 'center', width: '100%', padding: '20px', marginTop: '10px' }}>
    <Typography variant="h6" gutterBottom>
      No Smart Sales Generated Yet
    </Typography>
    <Typography variant="body2" color="green">
      <Link to="/post" style={{ textDecoration: 'none', color: 'inherit' }}>
        Post and share to generate user interest
      </Link>
    </Typography>
  </Card>
)}


      </Card>
   

  </div>
  )
}

export default KupsterDash