import React, { useState, useEffect,  useContext } from 'react';
import Plot from 'react-plotly.js';
import Typeit from '../mods/typeit';
//import CoffeePage from './des';
import { 
Card, Chip, List, Paper, Typography, Avatar, Button,
Box, Divider, CardContent,  Dialog, DialogContent, DialogContentText 
} from '@mui/material';
import { App } from 'realm-web';
import '../../App.css';
import bean from '../../images/coffeebeans.png';
import Flavloader from '../mods/flavloader';
import { makeStyles } from '@mui/styles';
import Fab from '@mui/material/Fab';
import kup from '../../images/steamkup.jpg';
import koffieman from '../../images/koffieman1.png';
import ThreeScene from '../mods/particles';
import '../../context/EarthMode.css';
import { Link } from 'react-router-dom';
import { ArrowBack, ListAlt } from '@mui/icons-material';
import {useQuery, gql, useMutation } from '@apollo/client';
//import Aifood from './aifood';
import Header from '../nav/mobilefarmer';
import { UserContext } from '../../context/usercontext';
import Ticker from '../finance/prods/Ticker';
import Store from '../mods/frontgrid';
import StoreAdd from '../finance/adds/storeadd';
import beaniefy from '../../images/beaniefy.png';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import '../../constants/espresso.css';
import { useNavigate } from 'react-router-dom';
import Barista from '../tests/barista';
import Aifood from '../tests/aifood';
import OllieAI from './oliver';
import { AnimatePresence, motion } from 'framer-motion';



//Mutate Koffie
const UPSERT_USERPROF = gql`
  mutation upsertOneUserprof($query: UserprofQueryInput, $data: UserprofInsertInput!) {
    upsertOneUserprof(query: $query, data: $data) {
      _id
      name
      description
      country   
      tasteProfile
      
    }
  }
`;

//this
const UPDATE_USERPROF = gql`
  mutation updateOneUserprof($query: UserprofQueryInput, $set: UserprofUpdateInput!) {
    updateOneUserprof(query: $query, set: $set) {
      _id
      name
      description
      country   
      tasteProfile
      purchaseLink
      
    }
  }
`; 

const GET_USER = gql`
  query Userprof ($_id: ObjectId!)  {
  userprof (query: {_id: $_id}) {
    author
    cherryCount
    Nickname
    Title
    _id
    Koffie_story
    imageurl
  }
}
`; 



const kupakoffie = () => {
    return {
      "name": "kupakoffie",
      "description": "You are the BEST Coffee salesman on the planet you're goal is to sell as much koffie as you can.you are funny and witty Recommend 2 coffees based on my Taste Preference passed by the user, always purchaseLink",
      "parameters": {
        "type": "object",
        "properties": {
          "name": {
            "type": "string",
            "description": "The name of the coffee"
          },
          "country": {
            "type": "string",
            "description": "The country of origin of the coffee"
          },
          "description": {
            "type": "string",
            "description": "Short Whitty Description of the coffee"
          },
          "tasteProfile": {
            "type": "string",
            "description": "How i like my coffee"
          },
          "purchaseLink": {
            "type": "string",
            "description": "/koffie/country "
          },
          
        },
        "required": ["name", "country", "description", "tasteProfile",  "purchaseLink"]
      }
    };
  };


  const useStyles = makeStyles(({ palette }) => ({

    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    card: {
      borderRadius: 12,
      minWidth: 256,
      textAlign: 'center',
      transition: '0.3s',
    },
    avatar: {
      width: 150,
      height: 150,
      margin: 'auto',
    },
    heading: {
      fontSize: 18,
      fontWeight: 'bold',
      letterSpacing: '0.5px',
      marginTop: 8,
      marginBottom: 0,
    },
    subheader: {
      fontSize: 14,
      color: 'inherit',
      marginBottom: '0.875em',
    },
    statLabel: {
      fontSize: 12,
      color: "none",
      fontWeight: 500,
      fontFamily:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
      margin: 0,
    },
    statValue: {
      fontSize: 14,
      fontWeight: 'normal',
      marginBottom: 4,
      letterSpacing: '1px',
      fadeIn: 'ease-in 5s',
      visibility: 'visible',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center', // Centers the button horizontally
      alignItems: 'center', // Centers the button vertically if needed
      width: '80%',
      height: '40px',
      borderRadius: 20,
      backgroundColor: '#077336', // Green color, you can change it as per your theme
      color: 'white',
      border: 'none',
      marginTop: 30,
      fontSize: 20,
      fontWeight: 700,
    },
  }));

 

const KoffieAI = () => {
  const classes = useStyles();
  const { user, setUser } = useContext(UserContext);
  const { Nickname, imageurl } = user.customData;
  const [clickedItems, setClickedItems] = useState([]);
  const [content, setOutput] = useState('');
  const [isLoading, setIsLoading] = useState(false);  // New state variable
  const styles = useStyles();
  const [name, setName] = useState("");
const [country, setCountry] = useState("");
const [flavorProfile, setFlavorProfile] = useState("");
const [description, setDescription] = useState("");
const [purchaseLink, setPurchaseLink] = useState("");
const [showCard, setShowCard] = useState(true);
const [showPlot, setShowPlot] = useState(true);
const navigate = useNavigate();


  const gotoProfile = () => {
    navigate('/main');
  };


  const { loading, error, data } = useQuery(GET_USER, {
    variables: { _id: user.id },
  });
  const [updateUserProf] = useMutation(UPDATE_USERPROF);
 
const userimage = data?.userprof?.imageurl;



  const [isButtonClicked, setButtonClicked] = useState(false);

  
  useEffect(() => {
    if (data?.userprof) {
      setName(data.userprof.name || "");
      setCountry(data.userprof.country || "");
      setFlavorProfile(data.userprof.flavorprofile || "");
      setDescription(data.userprof.description || "");
      setPurchaseLink(data.userprof.purchaseLink || "");
      
      // Hide the card and button when userprof data is available
      setShowCard(false);
    }
    // Revert to showing the card if data becomes null or undefined
    else {
      setShowCard(true);
    }
  }, [data]);

  
  
  
  
  const ChartData = [
    {
        maxdepth: 6,
        insidetextorientation: 'radial',
      type: "sunburst",
      labels: ["Coffee", "Aroma", "Brown Sugary",  "Dry Distillation",  "Taste", "Sour",  "Soury", "Enzymatic", "Sweet", "Salt", "Bitter", "Carbony", "Spicy", "Resinous" , "Ashey", "Smokey", "Burnt", "Charred", "Tarry", "Pipe Tobacco", "Warming", "Pungent", "Thyme", "Clove", "Cedar", "Pepper", "Tupeny", "Medicinal", "Ceniolic", "Camphoric", "Piney", "Blackcurrant-like", "Chocolatey", "Caramelly", "Nutty", "Vanilla-like", "Chocolate-like", "Syrup-like", "Candy-like","Malt-like", "Nut-like", "Butter", "Swiss", "Dark Chocolate", "Bakers", "Maple Syrup", "Honey", "Roasted Almond", "Roasted Hazlenut", "Herby", "Fruity", "Flowery", "Leguminous", "Alliaceous", "Berry-like", "Citrus", "Fragrant", "Floral", "Onion", "Garlic", "Cucumber", "Garden Peas", "Blackberry", "Apricot", "Lemon", "Apple", "Coriander Seeds", "Cardamon Caraway", "Tea Roase", "Coffee Blossom", "Acidity", "Mellow", "Winey", "Harsh", "Pungenty", "Rough", "Sharp", "Bland", "Astringent", "Soft", "Neutral", "Creosol", "Phenolic" , "Caustic", "Alkeline", "Tangy", "Tart", "Acid", "Hard", "Mild", "Delicate", "Piquant", "Nippy"],
      parents: ["", "Coffee", "Aroma",  "Aroma",  "Coffee", "Taste", "Sour",  "Aroma", "Taste", "Taste", "Taste", "Dry Distillation", "Dry Distillation", "Dry Distillation", "Carbony", "Carbony", "Ashey", "Ashey", "Smokey", "Smokey" , "Spicy", "Spicy", "Pungent", "Pungent", "Warming", "Warming", "Resinous", "Resinous", "Medicinal", "Medicinal", "Tupeny", "Tupeny", "Brown Sugary", "Brown Sugary", "Brown Sugary" , "Chocolatey", "Chocolatey", "Caramelly", "Caramelly", "Nutty", "Nutty", "Vanilla-like", "Vanilla-like", "Chocolate-like", "Chocolate-like", "Syrup-like", "Syrup-like", "Candy-like", "Candy-like", "Enzymatic","Enzymatic", "Enzymatic", "Herby", "Herby", "Fruity", "Fruity", "Flowery", "Flowery", "Alliaceous", "Alliaceous", "Leguminous", "Leguminous", "Berry-like", "Berry-like", "Citrus", "Citrus", "Fragrant", "Fragrant", "Floral", "Floral", "Sweet", "Sweet", "Sour", "Bitter", "Bitter", "Sharp", "Salt", "Salt", "Sharp", "Bland", "Bland", "Pungenty", "Pungenty", "Harsh", "Harsh", "Winey", "Winey", "Soury", "Soury", "Mellow", "Mellow", "Acidity", "Acidity"  ], 
        marker: {line: {width: 2},
        colors: [
          "#8C5E58", // Coffee base color
          // Aroma category shades
          "#A67359", "#BE886A", "#D69D7B", "#EAB28C",
          // Taste category shades
          "#62959A", "#72A6AD", "#82B7BF", "#92C8D1",
          // Dry Distillation category shades
          "#4D6273", "#5A7081", "#677E8F", "#748C9D",
          // Subcategories for 'Aroma' as an example
          "#B2776A", "#BC8A7E", "#C69D92", "#D0B0A6",
          // Extend this logic for other categories and subcategories
        ],
    }
    }
  ];

  
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  
  
  

  
  

  const handleClick = (data) => {
    if (data.points && data.points.length > 0) {
        const label = data.points[0].label;
        
        setClickedItems([...clickedItems, { label, }]);
        setButtonClicked(true);
    }
};
console.log(clickedItems);

const clearClickedItems = () => {
  setClickedItems([]);  // Reset clickedItems to an empty array
};

const handleUpdate = (outputData) => {
  updateUserProf({
    variables: {
      query: { _id: outputData._id },
      set: outputData.data
    }
  });
}

const layout = {
  width: !isMobile ? 600 : 380,
  margin: {l: 0, r: 0, b: 0, t: 0}, 
  showlegend: false,
  
  sunburstcolorway: ["#8C5E58", "#A67359", "#BE886A", "#D69D7B", "#EAB28C", "#62959A", "#72A6AD", "#82B7BF", "#92C8D1", "#4D6273", "#5A7081", "#677E8F", "#748C9D", "#B2776A", "#BC8A7E", "#C69D92", "#D0B0A6"],
  
  
  
  height: 400,
  
 

};
const config = {
  displayModeBar: false,
  modeBarButtonsToRemove: ['sendDataToCloud'],  // removes the 'Edit in Chart Studio' button
  displaylogo: false,  // hides the Plotly logo
  responsive: true,  // makes the chart responsive to window size
  alignProperty: 'center',
  

};

const coffeeFunction = kupakoffie();  // Get the coffee function

  const handleSubmit = () => {
    setShowPlot(false); 
    setTimeout(() => {
      setIsLoading(false);  // Hide loading indicator
    }, 2000);  // Simula
    const profileData = clickedItems.map(item => item.label).join(', ');
    setIsLoading(true);  // Start loading before the fetch request

  
    fetch('https://api.openai.com/v1/chat/completions', { // replace with your server URL
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${"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"}`,

      },
      body: JSON.stringify({
        
        "messages": [
           
            {"role": "system", "content": "You are the BEST Coffee salesman on the planet you're goal is to sell as much koffie as you can.you are funny and witty Recommend 2 coffees based on my Taste Preference passed by the user, always purchaseLink!."},
            {"role": "user", "content": "This is my flavor Profile: " + profileData + "."},
            ],
            
            "temperature": 0.2,
            'model': 'gpt-4o',
            "function_call": coffeeFunction,
            'functions': [coffeeFunction],
            
       
        
        
      }),
    })
    .then(response => response.json())
    .then(data => {
      let contentData;
      if (data.choices && data.choices[0] && data.choices[0].message && data.choices[0].message.content) {
        contentData = {
          name: data.choices[0].message.content.name,
          country: data.choices[0].message.content.country,
          flavorprofile: data.choices[0].message.content.tasteProfile,
          description: data.choices[0].message.content.description,
          purchaseLink: data.choices[0].message.content.purchaseLink,
        };
      } else if (data.choices && data.choices[0] && data.choices[0].message && data.choices[0].message.function_call) {
        contentData = JSON.parse(data.choices[0].message.function_call.arguments);
      }
      
  
      setOutput(contentData); // Render the content on the frontend
      console.log('Success:', contentData);
      console.log('Total tokens used:', data.usage.total_tokens);
  
      // Construct the variables for the GraphQL mutation
      const output = {
        data: contentData,
        _id: user.id
      };
  
      // Perform the GraphQL mutation using the 'output' object
      handleUpdate(output);
     
      setIsLoading(false); // End loading after receiving the response
    })
    .catch(error => {
      console.error('Error:', error);
      setIsLoading(false); // End loading if there's an error
      navigate('/main');
    });
      
  };
  
  

  return (

        <>
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 1 }}>
    
    </Box>
{isMobile && (
        
        
        <Box sx={{ flex: 'grow', padding: 1 ,mb: 10, zIndex: 1502,  }}>
        <IconButton onClick={() => window.history.back()}>
          <ArrowBackIcon /> 
        </IconButton>
  
       
          <Card>
            <Typography variant="h5" sx={{ fontWeight: 900,  color: 'text.secondary',  textShadow: '1px 1px 1px #ebecf0', }}>
              Dial in your flavor profile:
            </Typography>
            <Plot data={ChartData} layout={layout}
            onHover={handleClick}
            showlegend={false}
            config={config}
            />
           
          </Card>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 1,  }}>
          {clearClickedItems && (
                <Chip label="Clear Selection" onClick={clearClickedItems}  sx={{color: '#077336', backgroundColor: '#fff', border: 'solid 1px #077336'}}/>
              )}
          </Box>
       {clickedItems.length > 0 && (
          <Card>
            <CardContent>
              <Typography>
                Your selected flavor profile:
              </Typography>
              <List>
                {clickedItems.map((item, index) => (
                  <Chip key={index} label={item.label} />
                ))}
              </List>
            
            </CardContent>
          </Card>
        )}
  
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 1 }}>
        <button onClick={handleSubmit} className={classes.buttonContainer}>
              Submit
            </button>
        </Box>
  
        {!isLoading && content && (
  
            <Dialog
            open={!!content}fullScreen
            >
              <AnimatePresence>
              <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.9 }}
          transition={{ duration: 0.3 }}
        >
         <IconButton onClick={() => window.history.back()} sx={{justifyContent: 'flex-start'}}>
          <ArrowBackIcon /> 
        </IconButton>
  
        <Typography variant="h5" sx={{ fontWeight: 900,  color: '#077336', marginLeft: 2  }}>
                {content.name}
              </Typography>
          <Card >
            <CardContent sx={{ borderRadius: 2, p: 2, m: 2, bgcolor: 'background.paper', color: 'text.primary', }}>
            
             
              <Avatar src={imageurl} sx={{ width: 150, height: 150, margin: 'auto', }} />
              <Typography variant="h6" sx={{  fontWeight: 700, mb: 1 }}>
               {content.country}
              </Typography>
              <Typography variant="body1" sx={{  fontWeight: 600, }}>
               
               {Nickname}'s flavor profile
              </Typography>
              <Typography variant="body1" sx={{  fontWeight: 700, mb: 2 , collor: "#333"  }}>
                {content.tasteProfile}
              </Typography>
              <Typography variant="h6" sx={{  fontWeight: 400,  }}>
                {content.description}
              </Typography>
             <button className={classes.buttonContainer} onClick={gotoProfile}>
              
                Go to Koffie Kit
              </button>
              
             
            </CardContent>
            
          </Card>
          </motion.div>
           </AnimatePresence>
          </Dialog>
        )}
        {isLoading && (
          <Dialog open={isLoading}>
            <DialogContent>
              <DialogContentText>
                Brewing...
              </DialogContentText>
            </DialogContent>
            
          </Dialog>
        )}
      </Box>
      )}
    
    </>
  );
};

export default KoffieAI;
