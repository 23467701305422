import React from 'react';
import {  Typography,  Paper, CardMedia, Card, Grid, 
 Button, List, Chip, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Stack from '@mui/material/Stack';
import congo from '../../../images/congobag.png';
import { styled } from '@mui/material/styles';
import { gql,  useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom'
import Progress from '../../mods/progress';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import { useBag } from '../../../context/BagContext';
import { UserContext } from '../../../context/usercontext';





const GET_PROD = gql`
  query Prod ($_id: ObjectId!)  {
  aiadd (query: {_id: $_id}) {
   When
   price
   userID
    description
    imageurl
    primaryImage
    _id
    Title
  
  }
}
`; 

export const INSERT_CART_MUTATION = gql`
mutation InsertOneCart($data: CartInsertInput!) {
  insertOneCart(data: $data) {
    userID
    productId
    price
    name
    isPaid
    prodowner
    quantity
    options {
      size
      color
    }
  }
}
`;

const Item = styled(Paper)(({ theme }) => ({
	
	padding: theme.spacing(1),
	textAlign: 'center',

	boxShadow: 'none'
  }));

const useStyles = makeStyles(() => ({
  root: {
	padding: '10px',
   
    borderRadius: 20,
    boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',  // This is an example of a light top shadow style
      transition: '0.3s',
      backgroundImage: `url(${''})`, // Replace with the URL of your image
    backgroundSize: 'cover', // Cover the entire card with the image
    backgroundRepeat: 'no-repeat', // Do not repeat the image
    backgroundPosition: 'center', // Center the image
     
  },
  content: {
    padding: 10,
    spacing: 8,
  },
  brandCardHeader: {
    // Add styles for BrandCardHeader here
    fontSize: 25,
    fontWeight: 600,
    textAlign: 'left',
	margin: '0px' /* or a smaller value than what's currently set */
	
    
  },
  textInfoContent: {
    // Add styles for TextInfoContent here
  },
  button: {
    // Add styles for the button here
    backgroundColor: '#32a852',
    color: 'white',
    borderRadius: 20,
    padding: 10,
    border: 'none',
    width: '40%',
    height: 40,
    marginTop: 10,
    '&:hover': {
      backgroundColor: '#077336',
    },
  },
}));


const FromAdds = ({ onAddToBag }) => {
  const { user } = React.useContext(UserContext);
    const classes = useStyles();
    const { _id } = useParams()
    const { loading, data, error } = useQuery(GET_PROD, {
        variables: { _id: _id }
      });
     
      const [insertCart, ] = useMutation(INSERT_CART_MUTATION);

 
    if (loading) return <p><Progress/></p>;
if (error) return <p>Error: {error.message}</p>;

 const product = {
    id: data.aiadd._id, 
    name: data.aiadd.Title,
    price: data.aiadd.price,
    image: data.aiadd.primaryImage, // adjust this according to your schema
    description: data.aiadd.description,
    
     // Assuming this is the default value
   
};

 const { aiadd } = data;
 
const handleAddToCart3 = async (product) => { 
  const cartData = {
    query: { userID: user.id },  // Assuming user.id is securely fetched
    data: {
      userID: user.id,  // Possibly redundant; ensure alignment with your backend logic
      productId: product.id,
      quantity: 1,
      name: product.name,
      isPaid: false, 
      prodowner: aiadd.userID,  // Assuming this is the product owner's ID
      price: product.price,
      imageurl: product.image,  // Adjust this according to your schema
      options: {  // Options need to be nested within each cart item
        size: "M",
        color: "Red"
      }
    }
  };

  try {
    await insertCart({
      variables: cartData
    });
    onAddToBag(product);  // UI feedback action
  } catch (error) {
    console.error("Error inserting cart:", error);
    // Optionally handle error in the UI
  }
};


  
  return (
    
    <Stack spacing={2} sx={{paddingTop: 5}} >
      <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'left', }}>
      <ArrowBackIos  sx={{ fontSize: 30, color: 'black', marginLeft: 2 }} onClick={() => window.history.back()} />
     <Typography variant='h5' sx={{ textAlign: 'center', fontWeight: 800, color: 'text.secondary'  }}>
      {product.name}
      </Typography>
      </Box>
        <Card  sx={{}}>
        <CardMedia sx={{
  
  paddingTop: '56.25%', // Consider removing this line if you're setting height explicitly below
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  width: '100%',
  borderRadius: '0px',
  boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',
  transition: '0.3s',
  backgroundImage: `url(${product.image})`, // Replace with the URL of your image
  backgroundSize: 'cover', // Ensures the whole image fits within the box, might show empty space if aspect ratio differs
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  marginBottom: '10px',
  // Set a specific height for the CardMedia to ensure it's not zero or too small
  height: '300px', // Adjust this value based on your layout requirements
}} />

	<Typography 
   
  style={{ marginBottom: 0 }}  // Add this line
>
  
  
</Typography>

<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
<Grid item xs={6}>
          <Item >
			<Typography variant='h6' sx={{ textAlign: 'left'}} >
			Description
			</Typography>
			<Typography variant='body1' sx={{ textAlign: 'left'}} >
				{product.description}
			</Typography>
		  </Item>
        </Grid>
        <Grid item xs={6}>
        <Item sx={{fontWeight: 'bold', fontSize: '34px', }}>${product.price}</Item>
        </Grid>
       
        <Grid item xs={12}>
          <Item sx={{fontWeight: 'normal', fontSize: '18px', textAlign: 'left'}}>
           <List sx={{ }}>
            <Typography variant='h6' sx={{ textAlign: 'left'}} >
           Freat Atrisinal Work Made uniquely for you  
           </Typography>
           </List>
           
            </Item>
            
          </Grid>
          <Grid container justifyContent="center" alignItems="center" paddingBottom={10} >
	<button className={classes.button} onClick={() => handleAddToCart3(product)} >
    Add to Bag
  </button>

  </Grid>
		</Grid>

	
    
</Card>

</Stack>
  );
};

export default FromAdds;
