import React, { useContext } from 'react';
import { useQuery, gql } from '@apollo/client';
import { 
  Button, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Typography, 
  Box, 
  CircularProgress 
} from '@mui/material';
//import { GET_USER_REDEEMS } from '../../../context/gqllogics';
import { UserContext } from '../../../context/usercontext';



export const GET_USER_REDEEMS = gql`
  query GetUserRedeems($shopId: ObjectId!) {
    shopredeems(query: { shopId: $shopId }) {
      _id
      userId
      shopId
      beanCount
      timestamp
      name
      uimage
    }
  }
`;


const ShopRedeemList = () => {
  const { user } = useContext(UserContext);

  const { loading, error, data } = useQuery(GET_USER_REDEEMS, {
    variables: { shopId: user.id }, // Assuming shopId is the same as user.id
  });

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString();
  };

  return (
    <Box sx={{ padding: 0}}>
      <Typography variant="h6" gutterBottom sx={{fontWeight: 700,  textAlign: 'center' }}>
        Your Redeemed Beans
      </Typography>

      {loading && <CircularProgress />}
      {error && <Typography color="error">Error: {error.message}</Typography>}

      {data && data.shopredeems && (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <TableContainer component={Paper} sx={{ borderRadius: '10px 10px 0 0', }}>
          <Table>
            <TableHead sx={{backgroundColor: '#bce3c0', borderRadius: '15px 15px 0 0', fontWeight: 800}}> 
              <TableRow sx={{fontWeight: 800}}>
                <TableCell sx={{fontWeight: 800}} > Image</TableCell>
                <TableCell sx={{fontWeight: 800}}>Kupster</TableCell>
                <TableCell sx={{fontWeight: 800}}>Beans Claimed</TableCell>
                <TableCell sx={{fontWeight: 800}}>Redeem ID</TableCell>
                <TableCell sx={{fontWeight: 800}}>Shop ID</TableCell>
                <TableCell sx={{fontWeight: 800}}>Redeem Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.shopredeems.map((redeem) => (
                <TableRow key={redeem._id}>
                  <TableCell>
                    <img src={redeem.uimage} alt="Shop" style={{ width: '50px', height: '50px' }} />
                  </TableCell>
                  <TableCell>{redeem.name}</TableCell>
                  <TableCell>{redeem.beanCount}</TableCell>
                  <TableCell>{redeem._id}</TableCell>
                  <TableCell>{redeem.shopId}</TableCell>
                  <TableCell>{formatDate(redeem.timestamp)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            
          </Table>
          
        </TableContainer>
        <Box sx={{ display: 'flex', alignSelf: 'flex-end', marginTop: 2 }}>
  <Typography sx={{ color: '#352536', fontWeight: 800 }}>
    Total Redeemed Beans: {data.shopredeems.reduce((acc, curr) => acc + curr.beanCount, 0)}
  </Typography>
</Box>


        </Box>
        
      )}

      {data && data.shopredeems.length === 0 && (
        <Typography>No redeems found for your account.</Typography>
      )}

      {!data && !loading && (
        <Typography>
          You have not redeemed DigiBeans yet. Spread the word by letting your customers know.
        </Typography>
      )}
    </Box>
  );
};

export default ShopRedeemList;
