import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';


const guideSteps = [
    { text: 'Roasted where it grows', imageUrl: 'https://storage.googleapis.com/app_darkendimg/path/to/destination/ideogram%20(6).jpeg' },
     ];
export default function HilltopDialog() {
  const [open, setOpen] = useState(false);

  // Content for the Hilltop Dialog
  //const content = 'Welcome to Hilltop! Enjoy your stay.';

  useEffect(() => {
    if (!sessionStorage.getItem('hilltopDone')) {
      setOpen(true);
      sessionStorage.setItem('hilltopDone', 'true');
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
    open={open}
    onClose={handleClose}
    PaperProps={{
      sx: {
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },
    }}
  >
    <Box
      sx={{
        
        fontWeight: 800,
        textAlign: 'center',
        padding: 2,
        backgroundImage: 'url(https://storage.googleapis.com/app_darkendimg/youngbean.png)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: '60vh',
        borderRadius: 5,
        backgroundColor: '#077336',
        
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: 'auto',
          width: '100%',
          padding: 2,
        }}
      >
        <DialogTitle variant="h3" fontWeight={800} sx={{ mt: 1 , mb: 10, color: '#fff', textShadow: '2px 2px 4px #000000', padding: 0 }}>
          Disrupt Everything!
        </DialogTitle>
        <DialogActions sx={{ display: 'flex', justifyContent: 'center', mt: 15, position: 'absolute', bottom: 5, right: 0  }}>
        <Button
          onClick={handleClose}
          sx={{  color: '#fff', fontWeight: 800, border: '1px solid #fff', backgroundColor: 'transparent', }}
        >
          Close
        </Button>
      </DialogActions>
      </Box>
     
    </Box>
  </Dialog>
  
  );
}
