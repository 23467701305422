import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { gql, useQuery,useLazyQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { Card, Typography, Button, Box, CardMedia, CardContent, Divider, Grid, Chip, CardActionArea, Pagination, List, ListItem, Drawer,
CardActions, Paper, CardHeader, } from "@mui/material";
import { useParams, useLocation } from 'react-router'
import { GetKoffie, useKoffie } from '../queries/koffies';
import { get } from 'lodash';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { experimentalStyled as styled } from '@mui/material/styles';
import { green, red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import StoryCard from '../components/mods/comments';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Progress from '../components/mods/progress';
import Comtrade from '../components/finance/comtrade';
import CoffeeCalculator from '../components/finance/coffeecalc';
import CoffeePnL from '../components/finance/coffeecalc';
import Chat from '../components/profile/chat';
import ArrowForward from '@mui/icons-material/ArrowForward';
import KronDet from './storytales';
import vchain from '../images/vchain.png';
import Header from '../components/nav/mobilefarmer';
import redpill from '../images/redpill.png';
import ShopAd from '../components/finance/adds/shopadd';
import AiGen2 from '../components/finance/adds/aifeed2';
import koin from '../images/kcoin.png';
import { motion } from 'framer-motion';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


import {
  EmojiEvents as EmojiEventsIcon, // For Global Ranking
  ProductionQuantityLimits as ProductionQuantityLimitsIcon, // For Annual Output
  Thermostat as ThermostatIcon, // For Climate
  Terrain as TerrainIcon, // For Altitude Range
  CalendarToday as CalendarTodayIcon, // For Harvest Season
  Water as WaterIcon, // For Wet Process
  Dry as DryIcon, // Assuming this exists for Dry Process, or choose an alternative
  CheckBox
} from '@mui/icons-material';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import Welcomekland from '../components/mods/snacks/koffieland';
import ArrowBack from '@mui/icons-material/ArrowBack';


    


const countries = [
 
  
  { code: 'BI', label: 'Burundi', phone: '257' },
  { code: 'BJ', label: 'Benin', phone: '229' },
  { code: 'BO', label: 'Bolivia', phone: '591' },
  { code: 'BR', label: 'Brazil', phone: '55' },
  
  {
    code: 'CD',
    label: 'Democratic Republic of Congo',
    phone: '243',
  },
  { code: 'CM', label: 'Cameroon', phone: '237' },
  { code: 'CN', label: 'China', phone: '86' },
  { code: 'CO', label: 'Colombia', phone: '57' },
  { code: 'CR', label: 'Costa Rica', phone: '506' },
  { code: 'CU', label: 'Cuba', phone: '53' },
  {
    code: 'DO',
    label: 'Dominican Republic',
    phone: '1-809',
  },
  { code: 'EC', label: 'Ecuador', phone: '593' },
  { code: 'ET', label: 'Ethiopia', phone: '251' },
  { code: 'GT', label: 'Guatemala', phone: '502' },
  { code: 'GY', label: 'Guyana', phone: '592' },
  { code: 'HN', label: 'Honduras', phone: '504' },
  { code: 'HT', label: 'Haiti', phone: '509' },
  { code: 'ID', label: 'Indonesia', phone: '62' },
  { code: 'IN', label: 'India', phone: '91' },
  { code: 'JM', label: 'Jamaica', phone: '1-876' },
  { code: 'KE', label: 'Kenya', phone: '254' },
  { code: 'KH', label: 'Cambodia', phone: '855' },
  { code: 'UG', label: 'Uganda', phone: '855' },
  { code: 'MG', label: 'Madagascar', phone: '261' },
  { code: 'MW', label: 'Malawi', phone: '265' },
  { code: 'MX', label: 'Mexico', phone: '52' },
  { code: 'NI', label: 'Nicaragua', phone: '505' },
  { code: 'PA', label: 'Panama', phone: '507' },
  { code: 'PE', label: 'Peru', phone: '51' },
  { code: 'PG', label: 'Papua New Guinea', phone: '675' },
  { code: 'PH', label: 'Philippines', phone: '63' },
  { code: 'PR', label: 'Puerto Rico', phone: '1' }, 
  { code: 'PY', label: 'Paraguay', phone: '595' },
  { code: 'RW', label: 'Rwanda', phone: '250' },
  { code: 'SH', label: 'Saint Helena', phone: '290' },
  { code: 'SV', label: 'El Salvador', phone: '503' },
  { code: 'VE', label: 'Venezuela', phone: '58' }, 
  { code: 'VN', label: 'Vietnam', phone: '84' },
  { code: 'YE', label: 'Yemen', phone: '967' },
  
];


const GET_TOP_FIVE_KOFFIES_BY_GLOBAL_RANK = gql`
 query GetTopFiveKoffies{

koffies {
  countryName
  flag
   altitudeRange {
    low
    high
  }
  coffeeProduction {
    annualOutput
    globalRank
  }
  harvestSeason
  keyFacts
  imageurl 
  history
  climate
  social
  political
  varieties
  wet
  dry
  economics
  hybrid
  pounds
  varieties 
  flavorProfile
    _id

  }
  }
`;



const GET_KOFFIE_BY_COUNTRY = gql `

query aKoffie($countryName: String!){

koffy (query: {countryName: $countryName}){
  countryName
   altitudeRange {
    low
    high
  }
  coffeeProduction {
    annualOutput
    globalRank
  }
  harvestSeason
  keyFacts
  imageurl 
  history
  climate
  social
  political
  varieties
  wet
  dry
  economics
  hybrid
  pounds
  varieties 
  flavorProfile
    _id

  }

}
`
;

const GET_KRON_BY_COUNTRY = gql`
  query GetKronData($countryName: String!) {
    krons(query: {countryName: $countryName}) {
      year
    countryName
    What
    ImageUrl
    _id
    }
  }
`;

const GET_KOFFIE = gql`
query($countryName: String!){
  products(query: {countryName: $countryName}){
    name
    _id
    description1
    description2
    description4
    imageurl
    price
    countryName
  }
}
`;


// Insert one cart insertOneCart(data: CartInsertInput!): Cart

export const INSERT_CART_MUTATION = gql`
mutation InsertOneCart($data: CartInsertInput!) {
  insertOneCart(data: $data) {
    userID
    productId
    price
    beanies
    name
    quantity
    options {
      size
      color
    }
  }
}
`;

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
  }
  
  const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));
  
  const Item = styled(Box)(({ theme }) => ({
    backgroundColor: 'transparent',
    ...theme.typography.body2,
    padding: theme.spacing(0),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    
  }));
  

const AllKoffie = () => {
  const theme = useTheme();
  const [expandedCards, setExpandedCards] = React.useState({});
  
  const [drawerOpen, setDrawerOpen] = useState(false);
  
  
          const { _id } = useParams()
  const location = useLocation()
  const handleExpandClick = (id) => {
    setExpandedCards((prev) => ({ ...prev, [id]: !prev[id] }));
  };


const [value, setValue] = React.useState(countries[0]);
const [getKoffieByCountry, { data: koffieByCountry, loading, error }] = useLazyQuery(GET_KOFFIE_BY_COUNTRY);

const [getKronByCountry, { data: kronData, loading: kronLoading, error: kronError }] = useLazyQuery(GET_KRON_BY_COUNTRY);
const { data } = useQuery(GET_TOP_FIVE_KOFFIES_BY_GLOBAL_RANK);
const [getProductByCountry, { data: productData }] = useLazyQuery(GET_KOFFIE); 

// Use optional chaining to safely access nested data
const topFiveKoffies = data?.koffies ?? [];

console.log('Top Five Koffies:', topFiveKoffies);


const [dialogOpen, setDialogOpen] = useState(false);
const [tabValue, setTabValue] = useState(0);  
  const [selectedCard, setSelectedCard] = useState(null);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleCardClick = (card) => {
    setSelectedCard(card);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setDrawerOpen(false);
  };

  const handleDrawerToggle = () => {
    setDrawerOpen((prev) => !prev);
    console.log("Drawer toggled. New state:", !drawerOpen);
  };

  console.log("Drawer open state:", drawerOpen);


   

useEffect(() => {
  if (koffieByCountry?.koffy?.countryName) {
    getKronByCountry({ variables: { countryName: koffieByCountry.koffy.countryName } });
    getProductByCountry({ variables: { countryName: koffieByCountry.koffy.countryName } });

  }
  console.log('Updated kronData:', kronData);
}, [koffieByCountry, getKronByCountry, kronData, getProductByCountry]);

const [currentIndex, setCurrentIndex] = useState(0);
  const touchStartX = useRef(0);
  const touchEndX = useRef(0);
  const images = koffieByCountry?.koffy ? koffieByCountry.koffy.imageurl ? koffieByCountry.koffy.imageurl : [] :    []; // Replace with your images
  
  const [selectedKronId, setSelectedKronId] = useState(null);
  
  const handleDrawerOpen = (_id) => {
    setSelectedKronId(_id);
    setDrawerOpen(true);
  };
  
  
  const handleTouchStart = (e) => {
    touchStartX.current = e.targetTouches[0].clientX;
  };

  const handleTouchMove = (e) => {
    touchEndX.current = e.targetTouches[0].clientX;
  };

  const handleTouchEnd = () => {
    if (touchStartX.current - touchEndX.current > 75) {
      // Swiped left
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }
    if (touchStartX.current - touchEndX.current < -75) {
      // Swiped right
      setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    }
  };

  const selectedKronEntry = selectedKronId && kronData?.krons?.find(kron => kron._id === selectedKronId);


    
if (loading) return <div><Progress/></div>

if (error) return <div>Unexpected Error: {error.message}</div>
  return ( 
    
     
     
         <Box sx={{ flexGrow: 1,
  backgroundImage: `url('')`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  paddingTop: 2,
  marginBottom: 10,
  
  }}>
     <Welcomekland/>
        
      <Autocomplete
      
      sx={{
        mb: 0,
        mt: 0,
      
        '& .MuiOutlinedInput-root': {
          borderRadius: 5,
          
          
        },
      }}
      id="country-select-demo"
      
      options={countries}
      autoHighlight
      
      getOptionLabel={(option) => option.label}
      onChange={(event, newValue) => {
        if (newValue) {
          getKoffieByCountry({ variables: { countryName: newValue.label } });
          
        }
      }}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0, color: 'white', },  }} {...props} >
          <img
            loading="lazy"
            width="20"
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            alt=""
          />
          {option.label} ({option.code}) +{option.phone}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
        size='small'
        sx={{width: '100%', borderRadius: 5,  color: 'white', padding: 1}}
          {...params}
          label="Select a country to start your koffie journey"
          
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
    
    
   
        {/* Display loading state */}
        {loading && <p><Progress/></p>}

        {/* Display error state */}
        {error && <p>Error: {error.message}</p>}

        {/* Conditionally display data or a message */}
         
        {koffieByCountry ? (
            
        <Box sx={{  padding: 2 , borderRadius: 5, mt: 0 }}>
         {/* Render your koffie data here */}
        

<Card sx={{ minWidth: 275, margin: 'auto',  }}>

<Card sx={{ minWidth: 275, margin: 'auto', position: 'relative' }}>

<Card
        sx={{
          backgroundImage: `url(${images[currentIndex]})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: 400,
          width: '100%',
          position: 'relative',
          borderRadius: 0,
          color: 'white',
        }}
        onTouchMove={handleTouchMove}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        
      >
  <Box
    sx={{
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      justifyContent: 'center',
      padding: 1,
    }}
  >
    <Pagination count={images.length} page={currentIndex + 1} color="primary" />
  </Box>
</Card>

<Typography variant="h6" sx={{ fontWeight: 600, paddingLeft: 1, paddingTop: 1, 
overflow: 'hidden',
whiteSpace: 'nowrap',
textOverflow: 'ellipsis',
}}>
  {koffieByCountry?.koffy?.countryName 
    ? `${koffieByCountry.koffy.countryName}'s Koffie Products` 
    : 'Koffie Products'}
</Typography>

    <>
  {productData && productData.products ? (
    productData.products.length > 0 ? (
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        overflowX: 'auto',
        WebkitOverflowScrolling: 'touch',
        scrollbarWidth: 'none', /* For Firefox */
        msOverflowStyle: 'none' /* For Internet Explorer and Edge */
      }}>
        {productData.products.map((product) => (
          <div key={product._id} style={{ flex: '0 0 auto', padding: '8px' }}> {/* Adjust padding as needed */}
            <Card sx={{ boxShadow: 5, display: 'flex', flexDirection: 'row', width: 250 }}> {/* Adjust width as needed */}
              <CardMedia
                component="img"
                image={product.imageurl}
                alt={product.name}
                sx={{ width: 100, height: 'auto' }} 
              />
              <CardContent sx={{ padding: 2,  display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Typography gutterBottom variant="caption" sx={{ fontWeight: 500, textAlign: 'left' }}>
                  {product.name}
                </Typography>
                <Link to={`/product/${product._id}`}>
                  <Chip label={`$${product.price}`} color="success" />
                </Link>
              </CardContent>
            </Card>
          </div>
        ))}
      </div>
    ) : (
      <Typography variant="body1" color="textSecondary">No products found for selected country.</Typography>
    )
  ) : (
    <Typography variant="body1" color="textSecondary">Loading products...</Typography>
  )}
</>


   <Typography variant="h6" color='text.secondary' sx={{ fontWeight: 800}}>
      {koffieByCountry.koffy.countryName}'s Koffie Farmers
    </Typography>
<Header/>
<Box sx={{ flexGrow: 1, padding: 2 , backgroundColor: '#d8e4bc', borderRadius: 5, mt: 2 }}>
 
  <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 6, sm: 8, md: 12 }}>
    <Grid item xs={1} sm={4} md={4}>
      <Item>
        <EmojiEventsIcon sx={{ fontSize: 20, display: 'block', margin: '0 auto' }} />
        <strong>Ranking</strong>  
        <Typography variant="body2">
        {koffieByCountry.koffy.coffeeProduction ? koffieByCountry.koffy.coffeeProduction.globalRank : 'N/A'}
    </Typography>
      </Item>
    </Grid>
    <Grid item xs={2} sm={4} md={4}>
      <Item>
      
        <ProductionQuantityLimitsIcon sx={{ fontSize: 20, display: 'block', margin: '0 auto' }} />
        <strong>Output</strong>
        <Typography variant="body2">
        {koffieByCountry.koffy.coffeeProduction ? `${koffieByCountry.koffy.coffeeProduction.annualOutput} kgs` : 'N/A'}
    </Typography>
      </Item>
    </Grid>
    <Grid item xs={2} sm={4} md={4}>
      <Item>
        <ThermostatIcon sx={{ fontSize: 20, display: 'block', margin: '0 auto' }} />
        <strong>Climate</strong>
        <Typography variant="body2" sx={{ mb: 1 }}>
           Tropical
        </Typography>
      </Item>
    </Grid>
    <Grid item xs={2} sm={4} md={4}>
      <Item>
        <TerrainIcon sx={{ fontSize: 20, display: 'block', margin: '0 auto' }} />
        <strong>Altitude</strong> 
        <Typography variant="body2" sx={{ mb: 1 }}>
    {koffieByCountry.koffy.altitudeRange 
    ? `${koffieByCountry.koffy.altitudeRange.low} - ${koffieByCountry.koffy.altitudeRange.high} meterss` 
    : 'N/A'}
</Typography>
      </Item>
    </Grid>
    <Grid item xs={2} sm={4} md={4}>
      <Item>
        <CalendarTodayIcon sx={{ fontSize: 20, display: 'block', margin: '0 auto' }} />
        <strong>Harvest Season</strong>
        <Typography variant="body2" sx={{ mb: 1 }}>
           {koffieByCountry.koffy.harvestSeason}
        </Typography>
      </Item>
    </Grid>
    <Grid item xs={2} sm={4} md={4}>
      <Item>
        <WaterIcon sx={{ fontSize: 20, display: 'block', margin: '0 auto' }} />
        <strong>Wet Process</strong> 
        <Typography variant="body2" sx={{ mb: 1 }}>
        {koffieByCountry.koffy.wet ? (
  <Chip
    
    color="success"
    icon={<CheckCircleIcon />}
  />
) : (
  <Chip
    
    color="error"
    icon={<CancelIcon />}
  />
)}
    </Typography>
    
        <DryIcon sx={{ fontSize: 20, display: 'block', margin: '0 auto', marginTop: '8px' }} />
        <strong>Dry Process</strong>
        <Typography variant="body2" sx={{ mb: 1 }}>
        {koffieByCountry.koffy.dry ? (
  <Chip
   
    color="success"
    icon={<CheckCircleIcon />}
  />
) : (
  <Chip
   
    color="error"
    icon={<CancelIcon />}
  />
)}
    </Typography>
      </Item>
    </Grid>
  </Grid>
</Box>
    <Box sx={{ flexGrow: 1 , padding: 0}}>
    

  {kronData && kronData.krons ? (
    kronData.krons.length > 0 ? (
      <>
        <Typography variant="h5" component="div" sx={{ fontWeight: 700, color: 'text.secondary', paddingBottom: 1 }}>
          {kronData.krons[0].countryName}'s Koffie History
        </Typography>
        <div style={{ 
  overflowX: 'auto', 
  display: 'flex', 
  padding: 5, 
  scrollbarWidth: 'none', /* For Firefox */
  msOverflowStyle: 'none', /* For Internet Explorer and Edge */
  scrollbarColor: 'none' /* Not needed if scrollbarWidth is used, but left here for reference */
}}>
          {kronData.krons.map((kronEntry, index) => (
          <Box key={index} sx={{ 
            flex: 'none', 
            minWidth: 250, 
            maxWidth: 250, // Ensure consistency in card width
            marginRight: 2, 
            display: 'flex', 
            flexDirection: 'column', 
            justifyContent: 'space-between', 
            overflow: 'hidden', // Prevent content from spilling outside the card
            borderRadius: 5, // Optional: Add some border radius
            boxShadow: 0, // Optional: Add some shadow for depth
            bgcolor: 'background.paper', // Use theme's background color for the paper
          }}>
            <Card></Card>
              <CardMedia
                component="img"
                image={kronEntry.ImageUrl} // Replace with your image URL field
                alt={kronEntry.Title} // Replace with a suitable alt text
                sx={{ height: 140 }} // Adjust height as needed
              />
            
            <CardContent sx={{ flexGrow: 1, padding: 1 }}>
              <Typography gutterBottom variant="h6" component="div" sx={{ fontWeight: 700, color: 'text.primary' }}>
                {kronEntry.year}
              </Typography>
              <Typography variant="body2" component="div" sx={{ color: 'text.secondary', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical' }}>
                {kronEntry.What}
              </Typography> 
            </CardContent>
            <CardActions sx={{ justifyContent: 'space-between', padding: 1 }}>
             <Chip label={kronEntry.year} onClick={() => handleDrawerOpen(kronEntry._id)} />
            </CardActions>
        </Box>
        
          ))}
        </div>
      </>
    ) : (
      <Typography variant="body1" color="textSecondary">Searching data for selected country....</Typography>
    )
  ) : (
    <Typography variant="body1" color="textSecondary">Loading data...</Typography>
  )}

<Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
<Card sx={{ 
    display: 'flex', 
    width: 'calc(100% + 10px)', // Adjust the calculation based on actual body padding
          
    backgroundImage: `url(${selectedKronEntry?.ImageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: 300,
    borderRadius: 0,
    boxShadow: 0,
    paddingTop: 0,
    margin: 0,
    overflow: 'hidden', // Ensure no scrollbars appear due to negative margins
  }}>
    
  <ArrowBackIos onClick={() => setDrawerOpen(false)} sx={{ color: 'white',   textShadow: '4px 4px 8px #000000',  
}} />
    <Typography variant="h3" sx={{ fontWeight: 700, color: '#fff', }}>
      {selectedKronEntry?.countryName}
    </Typography>
  </Card>
  <Box sx={{ dispplay: 'flex', justifyContent: 'flex-start', padding: 0 }}>
  {selectedKronEntry && (
      
      <>
      
        
        
        <Typography variant="h4" component="div" sx={{ color: 'text.secondary' }}>
          {selectedKronEntry.year}
        </Typography>
        <Typography variant="h5" component="div" sx={{ fontWeight: 700, color: 'text.secondary' }}>
        {selectedKronEntry.countryName} Koffie History
        </Typography>
        <Typography variant="body2" component="div" sx={{ color: 'text.secondary' }}>
          {selectedKronEntry.What}
        </Typography>
      </>
    )}
    <AiGen2/>
    </Box>
</Drawer>

</Box>
  <Box
    sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
     
      padding: '10px',
    }}
  >
    <Typography variant="h3" sx={{ fontWeight: 800 , color: '#fff', textShadow: '4px 4px 8px #000000', }}>
      {koffieByCountry.koffy.countryName}
    </Typography>
  </Box>
</Card>

  <CardContent>
  <Typography variant="body1" sx={{ mb: 1, paddingBottom: 2 }}>
      <strong>History</strong> {koffieByCountry.koffy.history}
    </Typography>
    
   
   
    <Typography variant="body1" sx={{ mb: 1 }}>
  <strong>Flavor Profile</strong>
  {koffieByCountry.koffy.flavorProfile && Array.isArray(koffieByCountry.koffy.flavorProfile)  ?
    <List>
      {koffieByCountry.koffy.flavorProfile.map((profile, index) => (
        <ListItem key={index}>
          {profile}
        </ListItem>
      ))}
    </List>
    : 'N/A'
  }
</Typography>
    
        
    <Typography variant="body1" sx={{ mb: 1 }}>
      <strong>Social Impact</strong> {koffieByCountry.koffy.social}
    </Typography>
    <Typography variant="body1" sx={{ mb: 1 }}>
      <strong>Political Factors</strong> {koffieByCountry.koffy.political}
    </Typography>
    <Typography variant="body1" sx={{ mb: 1 }}>
      <strong>Economics:</strong> {koffieByCountry.koffy.economics}
    </Typography>

        <Typography variant="body1" sx={{ mb: 1 }}>
  <strong>Key Facts</strong>
  {koffieByCountry.koffy.keyFacts && Array.isArray(koffieByCountry.koffy.keyFacts) ?
    <List>
      {koffieByCountry.koffy.keyFacts.map((fact, index) => (
        <ListItem key={index}>
          {fact}
        </ListItem>
      ))}
    </List>
    : 'N/A'
  }
</Typography>
  </CardContent>
</Card>
<>
<AiGen2/>



</>
         <CardActions disableSpacing>
         <IconButton aria-label="add to favorites">
         <FavoriteIcon />
       </IconButton>
       <IconButton aria-label="share">
         <ShareIcon />
       </IconButton>
       
       </CardActions>
       <Divider />
       <CardContent>
         <Typography variant="h5" component="div" sx={{ fontWeight: 700
         , color: 'text.secondary' }}>
           Koffie History
         </Typography>
</CardContent>
       </Box>
       
        ) : (
          <Box sx={{ 
            flexGrow: 1,
            
             paddingTop: 1,
            
          }}>
             <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',  marginLeft: '-10px', marginRight: '-10px'  }}>
             <Card
            
  sx={{
    display: 'flex',
    flexDirection: 'column', // Make the card content stack vertically
    width: '100%',
    backgroundImage: `url(${''})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    borderRadius: 0,
    boxShadow: 0,
    paddingTop: 0,
    margin: 0,
    overflow: 'hidden',
  }}
>
  <CardContent>
    <Grid container spacing={2} justifyContent="flex-start" padding={0}>
      <Grid item xs={12} sm={12} md={12}>
        <Card
          sx={{
            boxShadow: 0,
            padding: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            boxShadow: 4,
          }}
        >
          {/* Welcome Text */}
          <CardContent
           component={motion.div}
           initial={{ opacity: 0, y: 50 }} // Start state (invisible and below)
           animate={{ opacity: 1, y: 0 }} // End state (fully visible and in place)
           transition={{ duration: 0.8, ease: 'easeOut' }} // Animation timing
          sx={{ padding: 1 }}>
            <Typography
              gutterBottom
              variant="h6"
              sx={{ fontWeight: 700, textAlign: 'left', paddingLeft: 1, paddingTop: 1 }}
            >
              Welcome to Koffieland
            </Typography>
            
            {/* Coffee Bean Image and Text */}
            <Grid container alignItems="center">
              <Grid item xs={8}>
                <Typography variant="body2" sx={{ textAlign: 'left', paddingLeft: 1 }}>
                 Explore the world of coffee growing countries and learn about their unique coffees
                </Typography>
              </Grid>
              <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <img
                  src={'https://storage.googleapis.com/app_darkendimg/newton.png'}
                  alt="Coffee Bean"
                  style={{ width: '100px', height: 'auto', objectFit: 'contain' }}
                />
              </Grid>
            </Grid>
          </CardContent>

          {/* List of Coffee Growing Countries */}

        </Card>
      </Grid>
    </Grid>
  </CardContent>
</Card>

    </div>
             <Grid container spacing={2} justifyContent="flex-start" padding={1}>
            
  {topFiveKoffies.map((koffie, item , index) => (
    <Grid item key={koffie._id} xs={4} sm={6} md={4}>
       <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.8 }}
        transition={{ duration: 0.5, ease: 'easeOut', delay: index * 0.2 }}
        style={{ padding: '10px', borderRadius: '5px', textAlign: 'center', fontSize: '12px', }}
        
      >
      <Card sx={{ borderRadius: 4, padding: 0, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', border: 'solid 0px',
        backgroundImage: `url(${koffie.imageurl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        boxShadow: 2,

       }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', padding: 0,
        
      borderRadius: '10px 10px 0 0',
      }}>
          <Avatar alt={koffie.countryName} src={koffie.flag} sx={{ width: '60px', height: '60px', margin: 1,  }} />
          
        </Box>
        <CardContent sx={{ padding: 1 , }}>
            
            {/* Add other Typography elements here as needed */}
          </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end', padding: 1,  }}>
          <Chip sx={{}} label={koffie.countryName} onClick={() => getKoffieByCountry({ variables: { countryName: koffie.countryName } })} />
        </CardActions>
      </Card>
      </motion.div>
    </Grid>
  ))}

</Grid>


      </Box>
    )}
    </Box>
  );
};

export default AllKoffie;