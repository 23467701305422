import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { UserContext } from '../../context/usercontext';
import { useContext } from 'react';
import Wallet from '../../constants/wallet';
import { Link } from 'react-router-dom';
import Button from '../../components/portal/Button';
import kcoin from '../../images/kcoin.png';
import { makeStyles } from '@mui/styles';
import { Chip } from '@mui/material';
import { useBag } from '../../context/BagContext';
import PaymentIcon from '@mui/icons-material/Payment';
import { useNavigate } from 'react-router-dom';
import { ShoppingBagOutlined } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  menuBox: {
  
    padding: 2,
    display: 'flex',
    width: '100%',
    margin: 'auto',
    justifyContent: 'space-around',
    marginTop: 20,
    
   
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '& a': {
      textDecoration: 'none',
      color: theme.palette.text.primary,
      '&:hover': {
        textDecoration: 'none',
      },
    },
  },
  menuItem: {
    fontSize: 16,
    fontWeight: 500,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  logoContainer: {
    paddingLeft: theme.spacing(0),
    paddingTop: theme.spacing(2),
  },
  walletContainer: {
    paddingRight: theme.spacing(5),
  },
}));

export default function DeskMenu() {
  const { user } = useContext(UserContext);
  const { itemCount } = useBag();
  const { Nickname, beanCount, imageurl } = user.customData;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const classes = useStyles();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlelogoClick = () => {
    navigate('/bag');
  }

  const handlepaid = () => {
    navigate('/payment-success');
  }

  return (

    
   
   <>
    {/* Column 1: Logo */}
   

    {/* Column 2: Menu Items */}


    
   
      <Box display="flex" justifyContent="space-around" className={classes.menuBox}>
     
        <Link to="/land"><Typography className={classes.menuItem}>Home</Typography></Link>
        <Link to="/serv"><Typography className={classes.menuItem}>Services</Typography></Link>
        <Link to="/beanwatch"><Typography className={classes.menuItem}>KaaF</Typography></Link>
        <Link to="/us"><Typography className={classes.menuItem}>Koffieman</Typography></Link>
        
         <Wallet />
       {itemCount > 0 ? (
    <Chip
      component={Link}
      to="/bag"
      label={`${itemCount} Checkout`}
      onClick={handlelogoClick}
      icon={<ShoppingBagOutlined />}
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 1, borderRadius: 5, backgroundColor: '#ffc107', color: '#fff'}}
    />
  ) : (
    <Avatar alt={Nickname} src={imageurl} sx={{ width: 30, height: 30 }} onClick={handleClick} 
    component={Link} to={`/userprof/${user.id}`} /> 
  )}
        
      </Box>
    

    {/* Column 3: Wallet and Icon */}
   
     
    
    </>
    

   
  );
}