import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography, Card, Grid, CardMedia, IconButton, List, ListItem, ListItemText, Divider, Drawer, Chip, Tabs, Tab } from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../context/usercontext';
import { useMutation } from '@apollo/client';
import { TextField, RadioGroup, FormControlLabel, Radio, Button } from '@mui/material';
import { UPDATE_USER_PROF, GET_USER } from '../../../context/gqllogics';
import ShopRedeemList from '../beanapi/shopreport';

export const GET_CART = gql`
  query GetCart($userID: ObjectId!) {
    carts(query: { userID: $userID, isPaid: true }) {
      userID
      beanies
      isPaid
      productId
      prodowner
      timeStamp
      name
      _id
      price
      imageurl
      quantity
      options {
        size
        color
      }
    }
  }
`;
const CashAppIcon = () => {
  return (
    <img src="https://storage.googleapis.com/app_darkendimg/assets/chashapp.jpg" alt="beans" style={{ width: 20, height: 20 }} />
  );
};

const ZelleIcon = () => {
  return (
    <img src="https://storage.googleapis.com/app_darkendimg/assets/zelle.png" alt="beans" style={{ width: 20, height: 20 }} />
  );
};

function BeanReport() {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [itemsInBag, setItemsInBag] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [open, setOpen] = useState(false);
  const [payout, setPayout] = useState('');
  const [cashappid, setCashAppId] = useState('');
  const [zelleid, setZelleId] = useState('');
  const [showPayoutForm, setShowPayoutForm] = useState(false);

  const { data: userData, loading: userLoading, error: userError } = useQuery(GET_USER, {
    fetchPolicy: 'cache-and-network',
    variables: { _id: user.id },
    skip: !user.id,
  });

  // set a constant for usertype coffeeShop is koffieshop
  const isKoffieShop = userData?.userprof.usertype === 'koffieshop';


  useEffect(() => {
    if (userData && userData.userprof) {
      setCashAppId(userData.userprof.cashappid || '');
      setZelleId(userData.userprof.zelleid || '');
      setPayout(userData.userprof.payout || '');
    }
  }, [userData]);

  const [updateUserProf] = useMutation(UPDATE_USER_PROF);

  const updatePayout = async (payout) => {
    try {
      await updateUserProf({
        variables: {
          query: { _id: user.id },
          set: { payout, cashappid: cashappid, zelleid: zelleid},
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  


  const handleDrawerOpen = (item) => {
    setSelectedItem(item);
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setSelectedItem(null);
  };
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const { data: cartData, loading: cartLoading, error: cartError } = useQuery(GET_CART, {
    fetchPolicy: 'cache-and-network',
    variables: { userID: user.id },
    skip: !user.id,
  });
 

  //Filter items by Product ID eqaul to user.id and count the total price

 const filteredItems = cartData?.carts.filter((item) => item.prodowner === user.id);
 const totalItems = filteredItems?.reduce((acc, item) => acc + item.price, 0);
 
 const takehome = totalItems * 0.9;
 
  useEffect(() => {
    if (cartData && cartData.carts) {
      const newItemsInBag = cartData.carts.map((item) => {
        return {
          name: item.name,
          price: item.price,
          imageurl: item.imageurl,
          farmer: item.farmer,
          _id: item._id,
          quantity: item.quantity,
          isPaid: item.isPaid,
          beanies: item.beanies,
          timeStamp: item.timeStamp,
          productId: item.productId,
         
        };
      });
      setItemsInBag(newItemsInBag);
    }
  }, [cartData]);
  

  const total = itemsInBag.reduce((acc, item) => acc + item.price, 0);
  const totalQuantity = itemsInBag.reduce((acc, item) => acc + item.quantity, 0);
  const totalBeanies = itemsInBag.reduce((acc, item) => acc + item.beanies, 0);
  
  const handleSave = async () => {
    const updatedFields = {};

    if (cashappid) {
      updatedFields.cashappid = cashappid;
    }
    if (zelleid) {
      updatedFields.zelleid = zelleid;
    }
    if (payout) {
      updatedFields.payout = payout;
    }

    try {
      await updateUserProf({
        variables: {
          query: { _id: user.id },
          set: updatedFields,
        },
      });
      setShowPayoutForm(false);
    } catch (error) {
      console.error(error);
    }
  };


  const isSaveDisabled = !cashappid && !zelleid;


  return (
    <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2, mb: 10, padding: 2 }}>
    <Tabs variant='contained' value={tabIndex} onChange={handleTabChange} sx={{ borderBottom: 1, borderColor: 'divider', fontSize: '2rem' }}>
      <Tab label="Earnings" sx={{ fontWeight: 900, width: '50%'  }} />
      <Tab label="Bean Report" sx={{ fontWeight: 900, width: '50%' }} />
    </Tabs>

    {tabIndex === 0 && (
      <>
       <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 0, mt: 2 }}>
            <Typography variant="body1" sx={{ fontWeight: 700, }}>
              Total Sales
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: 500, color: 'text.socondary' }}>
              ${totalItems}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="body1" sx={{ fontWeight: 700,  }}>
              Earnings
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: 500, color: 'text.socondary' }}>
              ${takehome}
            </Typography>
          </Box>
         
          <>
                      <Button onClick={() => setShowPayoutForm(!showPayoutForm)} sx={{ mt: 2, color: '#077336', fontWeight: 700, width: '50%', borderRadius: 10, border: 'solid 1px #077336', alignSelf: 'flex-end', backgroundColor: 'transparent' }}>
                        {showPayoutForm ? 'Hide Payout Methods' : 'Payment Methods'}
                      </Button>
                      </>
                      
          {showPayoutForm && (
            <Card sx={{ boxShadow: 0, borderRadius: 0, paddingBottom: 0, mt: 2 }}>
              <Typography variant="h6" sx={{ fontWeight: 700, mt: 0, ml: 2 }}>Payout</Typography>
              <Typography variant="body1" sx={{ ml: 2, mt: 2 }}>We currently pay out to CashApp and Zelle.</Typography>
              <Typography variant="caption" sx={{ ml: 2, mt: 2 }}>Stay tuned for more payment options.</Typography>

              <Grid container spacing={2} sx={{ mt: 2, p: 2 }}>
                <Grid item xs={12}>
                  <TextField
                    label="CashApp ID"
                    
                    value={cashappid}
                    onChange={(e) => setCashAppId(e.target.value)}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <IconButton position="start">
                          <CashAppIcon />
                        </IconButton>
                      ),
                      inputProps: {
                        style: {
                          textAlign: "right",
                          width: '100%',
                          borderRadius: '5px',
                          marginBottom: 0,
                          backgroundColor: '#bce3c0'
                        }
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Zelle ID"
                    value={zelleid}
                    onChange={(e) => setZelleId(e.target.value)}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <IconButton position="start">
                          <ZelleIcon />
                        </IconButton>
                      ),
                      inputProps: {
                        style: {
                          textAlign: "right",
                          width: '100%',
                          borderRadius: '5px',
                          marginBottom: 0,
                          backgroundColor: '#bce3c0'
                        }
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body1" sx={{ fontWeight: 700 }}>Select Default Payout Method:</Typography>
                  <RadioGroup
                    aria-label="payout"
                    name="payout"
                    value={payout}
                    onChange={(e) => setPayout(e.target.value)}
                  >
                    <FormControlLabel value="cashapp" control={<Radio />} label="CashApp" />
                    <FormControlLabel value="zelle" control={<Radio />} label="Zelle" />
                  </RadioGroup>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    
                    onClick={handleSave}
                    disabled={isSaveDisabled}
                    sx={{ mt: 1, backgroundColor: '#077336', color: 'white', fontWeight: 600, borderRadius: 20, border: 'solid 0px #077336', alignSelf: 'flex-end'}}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Card>
          
          )}

           
              <ShopRedeemList />
            

        <Grid container spacing={2}>
          {filteredItems && filteredItems.length > 0 ? (
            filteredItems.map((item, index) => (
              <Grid item key={index} xs={6} sm={6} md={4} sx={{mt: 2}}>
                <Card sx={{ boxShadow: 3, borderRadius: 2, paddingBottom: 0 , border: 'solid 0px #d1d1d1'}}>
                  <CardMedia
                    component="img"
                    sx={{ height: 200,  }}
                    image={item.imageurl}
                    alt={item.name}
                  />
                  <Box sx={{ p: 2 }}>
                    <Typography variant="body1" sx={{ fontWeight: 700 }}>{item.name}</Typography>
                    <Typography variant="body2" color="textSecondary">${item.price.toFixed(2)}</Typography>
                    <Chip label={`x${item.beanies}`} sx={{ backgroundColor: '#077336', color: 'white', mt: 1 }} onClick={() => handleDrawerOpen(item)} />
                  </Box>
                </Card>
              </Grid>
            ))
          ) : (
            <Typography sx={{ mt: 2, fontWeight: 700, padding: 2 }}
            >No Purchases yet.</Typography>
          )}
        </Grid>

       
      </>
    )}

    {tabIndex === 1 && (
      <Box sx={{ mt: 2 , display: 'flex', flexDirection: 'column', gap: 2 }}>


        <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2, mt: 2 }}>
          <Typography variant='body1' sx={{ fontWeight: 700 }}>Total Bean tokens {totalBeanies}</Typography>
          <Typography variant='body1' sx={{ color: '#077336', fontWeight: 700 }}>Total Spent: ${total}</Typography>
          <Typography variant='body1'>Total lbs: {totalQuantity}</Typography>
        </Box>
        <Typography variant="h6" sx={{ fontWeight: 700 }}>
          Purchases:
        </Typography>
        <List sx={{ mt: 4 }}>
          {itemsInBag && itemsInBag.length > 0 ? (
            itemsInBag.map((item, index) => (
              <Box key={index}>
                <ListItem sx={{ display: 'flex', alignItems: 'center' }}>
                  <CardMedia
                    component="img"
                    sx={{ width: 40, height: 40, objectFit: 'contain', borderRadius: 1 }}
                    image={item.imageurl}
                    alt={item.name}
                  />
                  <ListItemText
                    primary={
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body1" sx={{ marginRight: 2 }}>
                          {item.name}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          ${item.price.toFixed(2)}
                        </Typography>
                        <Chip label={`x${item.beanies}`} sx={{ backgroundColor: '#077336', color: 'white', ml: 2 }} onClick={() => handleDrawerOpen(item)} />
                      </Box>
                    }
                    sx={{ marginLeft: 2 }}
                  />
                </ListItem>
                {index < itemsInBag.length - 1 && <Divider />}
              </Box>
            ))
          ) : (
            <Typography>No Purchese yet.</Typography>
          )}
        </List>
      </Box>
    )}

    <Drawer anchor="right" open={open} onClose={handleDrawerClose}
    sx={{ width: 400, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', zIndex: 1500 }}
    >

      {selectedItem && (
        <Box sx={{ width: 400, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mt: 10, mb: 2 }}>
          <Typography variant="h4" fontWeight={700}>{selectedItem.name}</Typography>
          <img src={selectedItem.imageurl} alt={selectedItem.name} style={{ width: '200px', marginTop: 16 }} />
          <Typography variant="body1" sx={{ fontWeight: 500 }}>{selectedItem.name}</Typography>
          <Typography variant="body2" color="textSecondary" fontWeight={700}>{selectedItem.price}</Typography>
          <Typography variant="h6" color="textSecondary" sx={{ fontWeight: 500 }}>Beanies: {selectedItem.beanies}</Typography>
          <Typography variant="body2" color="textSecondary">Product ID: {selectedItem.productId}</Typography>
          <Typography variant="body2" color="textSecondary">Timestamp: {selectedItem.timeStamp}</Typography>
          <Typography variant="body2" color="textSecondary">Net Margin: {selectedItem.price * 0.9 }</Typography>
        </Box>
      )}
    </Drawer>
  </Box>
  );
}

export default BeanReport;
